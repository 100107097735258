import { Controller } from '@hotwired/stimulus'

export default class DiscretionaryDiscountController extends Controller {
  static targets = ['amountInput']

  onSubmit(event) {
    const oneHundredPercent =
      parseFloat(this.amountInputTarget.value) === parseFloat(this.amountInputTarget.dataset.maximumAmount)

    if (oneHundredPercent) {
      event.preventDefault()
      const confirmation = window.confirm(this.amountInputTarget.dataset.maximumAmountLabel)

      if (confirmation) {
        event.target.form.submit()
      }
    }
  }
}
