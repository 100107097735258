import { Controller } from '@hotwired/stimulus'

export default class IframeController extends Controller {
  static values = {
    loadedId: String,
  }
  static classes = ['is-loading', 'is-loaded']

  connect() {
    const iframeElement = this.element.contentWindow.document

    if (iframeElement.readyState === 'complete') {
      this.onLoad()
    }
  }

  onLoad() {
    this.element.classList.remove('is-loading')
    this.element.classList.add('is-loaded')
    this.element.id = this.loadedIdValue
  }
}
