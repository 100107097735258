import { waitForServerSideConfirmation, ResultDiv } from '../lib/iframe_card_fields'
import { show } from '../lib/helpers'

async function submitPaymentMethodForm(form) {
  const resultDiv = new ResultDiv()

  try {
    resultDiv.show('progress')
    const { paymentMethodId } = await waitForServerSideConfirmation(form.dataset.confirmationUrl)

    const input = form.querySelector('input[name="subscription[payment_method_id]"]')
    if (input) {
      input.value = paymentMethodId
    }

    form.submit()
  } catch (error) {
    resultDiv.show('error')
  }
}

function customerCanUseApplePay() {
  if (window.showApplePayForTests) {
    return true
  }

  return window.ApplePaySession && window.ApplePaySession.canMakePayments()
}

window.addEventListener('turbo:load', async function () {
  const action = document.querySelector('.js-apple-pay-action')

  if (action && customerCanUseApplePay()) {
    show(action)
  }
})

window.addEventListener('turbo:load', async function () {
  const form = document.querySelector('.js-windcave-apple-pay')
  if (form) {
    await submitPaymentMethodForm(form)
  }
})
