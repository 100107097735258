// Displays a notice under "Time zone" input if value doesn't match browser time zone
import { hide, show, interpolate } from '../lib/helpers'

function browserTimeZone() {
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (timeZone === 'UTC') {
    return 'Etc/UTC'
  }
  return timeZone
}

function showTimeZoneNoticeIfDifferent(currentValue, timeZoneSelect, timeZoneNotice, browserTimeZone) {
  const matchingBrowserOption = timeZoneSelect.querySelector(`option[value="${browserTimeZone}"]`)
  if (!matchingBrowserOption) {
    return
  }

  if (currentValue === browserTimeZone.replace(' ', '_')) {
    hide(timeZoneNotice)
  } else {
    const currentLabel = timeZoneSelect.querySelector(`option[value="${currentValue}"]`).innerText
    const browserLabel = matchingBrowserOption.innerText
    const differentLocalTimeZoneLabel = timeZoneNotice.dataset.differentLocalTimeZone
    const message = interpolate(differentLocalTimeZoneLabel, {
      report_time_zone: `<b>${currentLabel}</b>`,
      browser_time_zone: `<b>${browserLabel}</b>`,
    })
    timeZoneNotice.querySelector('p').innerHTML = message

    show(timeZoneNotice)
  }
}

function initializeTimeZoneNotice(timeZoneSelect, timeZoneNotice, browserTimeZone) {
  showTimeZoneNoticeIfDifferent(timeZoneSelect.value, timeZoneSelect, timeZoneNotice, browserTimeZone)

  timeZoneSelect.addEventListener('change', (e) => {
    showTimeZoneNoticeIfDifferent(e.target.value, timeZoneSelect, timeZoneNotice, browserTimeZone)
  })
}

document.addEventListener('turbo:load', () => {
  const timeZoneSelect = document.querySelector('.js-time-zone-select')
  const timeZoneNotice = document.querySelector('.js-time-zone-notice')

  if (timeZoneSelect && timeZoneNotice) {
    initializeTimeZoneNotice(timeZoneSelect, timeZoneNotice, browserTimeZone())
  }
})

export { initializeTimeZoneNotice }
