// Autocomplete its own search field

function initializeAutocomplete(searchField, accessibleAutocomplete) {
  if (searchField.firstChild) {
    searchField.removeChild(searchField.firstChild)
  }

  accessibleAutocomplete({
    element: searchField,
    id: searchField.dataset.id,
    className: 'FormField-input',
    cssNamespace: 'Autocomplete',
    displayMenu: 'overlay',
    required: searchField.dataset.required,
    placeholder: searchField.dataset.placeholder,
    showNoOptionsFound: false,
    name: searchField.dataset.name,
    source: function (query, populateResults) {
      const lowercaseQuery = query.toLowerCase()

      const filtered = JSON.parse(searchField.dataset.options).filter((option) => {
        return !!option.toLowerCase().includes(lowercaseQuery)
      })

      populateResults(filtered)
    },
  })
}

async function initializeAutocompleteSelfComplete(event) {
  const container = event.target.querySelector('.js-autocomplete-self-complete')
  if (container) {
    const searchField = container.querySelector('[data-options]')
    if (searchField) {
      const { default: accessibleAutocomplete } = await import('accessible-autocomplete')
      initializeAutocomplete(searchField, accessibleAutocomplete)
      searchField.querySelector('input').classList.add('FormField-input')

      if (searchField.dataset.required) {
        searchField.parentElement.classList.add('FormField--required')
      }

      if (searchField.dataset.value) {
        searchField.querySelector('input').value = searchField.dataset.value
      }
    }
  }
}

document.addEventListener('turbo:load', initializeAutocompleteSelfComplete)
document.addEventListener('turbo:render', initializeAutocompleteSelfComplete)
document.addEventListener('turbo:frame-render', initializeAutocompleteSelfComplete)
