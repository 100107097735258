import { Controller } from '@hotwired/stimulus'
import { hide, show } from '../../lib/helpers'

export default class CustomReportScheduleController extends Controller {
  static targets = ['type', 'dayOfWeek', 'dayOfMonth']

  connect() {
    this.toggleFields()
  }

  toggleFields() {
    const scheduleType = this.typeTarget.value

    if (scheduleType === 'weekly') {
      show(this.dayOfWeekTarget)
      hide(this.dayOfMonthTarget)
    } else if (scheduleType === 'monthly') {
      hide(this.dayOfWeekTarget)
      show(this.dayOfMonthTarget)
    }
  }
}
