/* global zE */

// Load and open Zendesk chat

import { brandColorPrimary } from '../lib/helpers'

function openAndPrepareWidget(chatButton) {
  if (typeof zE !== 'undefined') {
    zE('webWidget', 'open')

    zE('webWidget:on', 'close', () => {
      zE('webWidget', 'hide')
    })

    chatButton.addEventListener('click', () => {
      zE('webWidget', 'show')
      zE('webWidget', 'open')
    })
  }
}

function loadZendesk(chatButton, snippetUrl) {
  if (!document.getElementById('ze-snippet')) {
    const script = document.createElement('script')
    script.async = true
    script.id = 'ze-snippet'
    script.src = snippetUrl
    document.body.appendChild(script)
    script.addEventListener('load', () => openAndPrepareWidget(chatButton))
  }
}

async function zendeskChatButton() {
  const chatButton = document.querySelector('.js-zendesk-chat-button')

  if (chatButton) {
    const snippetUrl = chatButton.dataset.zendeskSnippetUrl
    if (snippetUrl) {
      window.zESettings = {
        webWidget: {
          color: { theme: brandColorPrimary() },
          chat: { connectOnPageLoad: false },
        },
      }

      chatButton.addEventListener('click', () => {
        loadZendesk(chatButton, snippetUrl)
      })
    }
  }
}

document.addEventListener('starfish:render', zendeskChatButton)

// Allow re-opening the widget after Turbo navigation
// https://mentalized.net/journal/2019/04/26/zendesk-web-widget-turbolinks/
window.addEventListener('turbo:before-render', () => {
  window.zEACLoaded = undefined
})

export default zendeskChatButton
