import { Controller } from '@hotwired/stimulus'
import { hide, show } from '../../lib/helpers'
import { debounce } from 'lodash'

export default class FaqSearchController extends Controller {
  static targets = ['form', 'input', 'spinner', 'searchResults']

  performSearchDebounced = debounce(this.performSearch.bind(this), 500)

  submit(e) {
    if (e.target.type !== 'submit') {
      e.preventDefault()
      this.updateSearchResults()
      if (this.inputTarget.value.length > 0) {
        show(this.spinnerTarget)
        this.performSearchDebounced()
      }
    }
  }

  performSearch() {
    const fetchUrl = this.application.fetch || fetch
    const url = new URL(this.formTarget.action)

    url.searchParams.append('search_text', this.inputTarget.value)

    fetchUrl(url)
      .then((response) => response.text())
      .then((html) => {
        this.updateSearchResults(html)
      })
  }

  updateSearchResults(html = '') {
    hide(this.spinnerTarget)
    this.searchResultsTarget.innerHTML = html
    this.performSearchDebounced.cancel()
  }
}
