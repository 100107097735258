// Load Swiper for "Carousel" UI component

async function carousel() {
  const carouselSelector = '.js-carousel'
  const carousels = document.querySelectorAll(carouselSelector)

  if (carousels.length) {
    const { default: Swiper, Autoplay, EffectFade, Pagination, A11y } = await import('swiper')
    await Promise.all([
      import('swiper/css'),
      import('swiper/css/effect-fade'),
      import('swiper/css/pagination'),
      import('swiper/css/a11y'),
    ])

    new Swiper(carouselSelector, {
      loop: true,
      allowTouchMove: false,
      effect: 'fade',
      speed: 2000,
      autoplay: {
        delay: 6000,
      },
      modules: [Autoplay, EffectFade, Pagination, A11y],
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  }
}

document.addEventListener('starfish:render', carousel)

export default carousel
