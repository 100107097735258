import { Controller } from '@hotwired/stimulus'

export default class ExpiryDateController extends Controller {
  static targets = ['monthInput', 'yearInput']

  connect() {
    this.updateMonthInput()
  }

  onYearChange() {
    this.updateMonthInput()
  }

  updateMonthInput() {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const selectedYear = parseInt(this.yearInputTarget.value) + 2000

    const minimumMonthBasedOnYear = selectedYear === currentYear ? currentMonth : 1
    this.monthInputTarget.min = minimumMonthBasedOnYear
  }
}
