// Sometimes we want to use `link_to method: :post`. However, that doesn't work with turbo.
// Turbo turns the form that ujs generates when it sees `method: :post` into an ajax form (as opposed to a full page load form) and then all hell breaks loose.
// So. Use `link_to class: 'js-form-link'` instead of `link_to method: :post` when turbo is on (that is, always).
window.addEventListener('click', (event) => {
  // NOTE: this assumes the target is the anchor itself, not a child of the anchor!
  if (event.target.classList.contains('js-form-link')) {
    event.preventDefault()
    const form = document.createElement('form')

    const csrfParam = document.querySelector('meta[name="csrf-param"]').content
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    const csrfInput = document.createElement('input')
    csrfInput.setAttribute('type', 'hidden')
    csrfInput.setAttribute('name', csrfParam)
    csrfInput.setAttribute('value', csrfToken)

    form.appendChild(csrfInput)

    form.action = event.target.href
    form.method = 'POST'

    form.dataset.turbo = 'false'
    document.body.appendChild(form)

    form.submit()
  }
})
