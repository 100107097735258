import { disableInput } from '../lib/helpers'

// reloads a turbo frame with new params when anything changes in a turbo frame form
document.addEventListener('change', function (event) {
  const element = event.target
  if (!element.disabled && element.classList.contains('js-reload-turbo-frame-on-change')) {
    const formData = new FormData(element.form)
    const inputTypeFile = Array.from(element.form.querySelectorAll('input[type=file]'))

    inputTypeFile.forEach(function (fileInput) {
      formData.delete(fileInput.name)
    })

    const urlSearchParams = new URLSearchParams(Array.from(formData))
    urlSearchParams.delete('authenticity_token')

    var bareUrl = element.closest('turbo-frame').src || ''
    if (bareUrl.includes('?')) {
      bareUrl = bareUrl.split('?')[0]
    }
    const url = bareUrl + '?' + urlSearchParams.toString()
    element.closest('turbo-frame').src = url
  }
})

// disable inputs in turbo frames during loading
document.addEventListener('turbo:before-fetch-request', function (e) {
  const element = e.target
  if (element.tagName === 'TURBO-FRAME') {
    const inputs = Array.from(element.querySelectorAll('input, button, select, textarea'))
    inputs.forEach(function (input) {
      const clone = input.cloneNode(true)
      input.parentNode.replaceChild(clone, input)
      disableInput(clone)
    })
  }
})

document.addEventListener('turbo:frame-render', function (e) {
  const pageBlock = e.target.closest('.js-page-block-edit-preview')
  const inputTypeFile = e.target.querySelector('input[type=file]')

  if (inputTypeFile) {
    inputTypeFile.form.setAttribute('enctype', 'multipart/form-data')
  }

  if (pageBlock) {
    if (e.target.querySelector('.js-page-block-cancel-edit')) {
      pageBlock.classList.add('u-borderDashed')
    } else {
      pageBlock.classList.remove('u-borderDashed')
    }
  }
})
