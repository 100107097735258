// Search optical/vet location address and populate prescription fields

import { addressFields, createSelectOption } from '../lib/helpers'

function getDisplayText(location) {
  if (!location) {
    return ''
  }
  return addressFields
    .map((field) => location[field])
    .filter(Boolean)
    .join(', ')
}

function initializeAutocomplete(addressSearchField, selectLocation, accessibleAutocomplete) {
  const locations = JSON.parse(addressSearchField.dataset.locations)
  const placeholder = addressSearchField.dataset.placeholder

  if (addressSearchField.firstChild) {
    addressSearchField.removeChild(addressSearchField.firstChild)
  }

  accessibleAutocomplete({
    element: addressSearchField,
    id: 'location_address_search',
    cssNamespace: 'Autocomplete',
    displayMenu: 'overlay',
    placeholder: placeholder,
    source: suggest,
    name: null,
    onConfirm: selectLocation,
    templates: {
      inputValue: () => '',
      suggestion: getDisplayText,
    },
  })

  async function suggest(query, populateResults) {
    const lowercaseQuery = query.toLowerCase()
    const filtered = locations.filter((location) => {
      return !!addressFields
        .map((field) => location[field])
        .filter(Boolean)
        .find((field) => field.toLowerCase().includes(lowercaseQuery))
    })

    populateResults(filtered)
  }

  const defaultId = addressSearchField.dataset.default
  if (defaultId) {
    selectLocation(locations.find((o) => o.id === defaultId))
  }
}

async function locationSearch(locationSearchContainer) {
  const addressSearchField = locationSearchContainer.querySelector('[data-locations]')
  if (addressSearchField) {
    const { default: accessibleAutocomplete } = await import('accessible-autocomplete')
    initializeAutocomplete(addressSearchField, selectLocation, accessibleAutocomplete)
  }

  function selectLocation(location) {
    if (location) {
      Object.keys(location).forEach((key) => {
        let value = location[key]
        // Rename address region key so the field on the page can be found
        if (key === 'region_code') {
          key = 'region'
        }
        // For optical practice locations we want to get the value from
        // the stores telephone and set the doctors telephone field
        if (
          key === 'telephone' &&
          locationSearchContainer.querySelectorAll('[data-content=doctors_telephone]').length > 0
        ) {
          key = 'doctors_telephone'
        }
        if (Array.isArray(value)) {
          locationSearchContainer.querySelectorAll(`[data-content=${key}]`).forEach((field) => {
            field.innerHTML = ''
            value.forEach((optionKeyValue) => {
              const selectOption = createSelectOption(optionKeyValue.text, optionKeyValue.value)
              field.appendChild(selectOption)
            })
            field.dispatchEvent(new CustomEvent('change'))
          })
        } else {
          locationSearchContainer.querySelectorAll(`[data-content=${key}]`).forEach((field) => {
            field.value = value
            field.dispatchEvent(new CustomEvent('change'))
          })
        }
      })
    }
  }
}

function initializeLocationSearch(event) {
  const locationSearchContainer = event.target.querySelector('.js-location-address-search')
  if (locationSearchContainer) {
    locationSearch(locationSearchContainer)
  }
}

document.addEventListener('turbo:load', initializeLocationSearch)
document.addEventListener('turbo:render', initializeLocationSearch)
document.addEventListener('turbo:frame-render', initializeLocationSearch)
