// Disable a submit button until a field has changed and then display notice to submit form

import { show, disableButton } from '../lib/helpers'

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.js-disable-until-change').forEach((container) => {
    const submitButton = container.querySelector('button[type=submit]:not([data-dont-disable-until-change])')
    if (submitButton) {
      disableButton(submitButton)
    }
    container.querySelectorAll('.FormField-select, .FormField-input').forEach((element) => {
      element.addEventListener('change', () => {
        submitButton.disabled = false
        submitButton.classList.remove('is-disabled', 'Button--outlined')
        submitButton.classList.add('Button--primary')
        const prompt = document.querySelector(container.dataset.prompt)
        if (prompt) {
          show(prompt)
        }
      })
    })
  })
})
