// Populates "Recall Date" field when "Check date" is set
import { addMonths, isoDateFormat, isValidDate, toggleValueClass } from '../lib/helpers'

function prescriptionRecallDateForCheckDate(container) {
  const checkDateInput = container.querySelector('.js-prescription-check-date-input')
  const recallDateInput = container.querySelector('.js-prescription-recall-date-input')

  if (checkDateInput && recallDateInput) {
    initializeRecallDateCalculationForCheckDate(checkDateInput, recallDateInput)
  }
}

function initializeRecallDateCalculationForCheckDate(checkDateInput, recallDateInput) {
  checkDateInput.addEventListener('change', calculateRecallDateForCheckDate)

  function calculateRecallDateForCheckDate() {
    if (checkDateInput.value) {
      const checkDate = new Date(Date.parse(checkDateInput.value))
      if (isValidDate(checkDate)) {
        recallDateInput.value = isoDateFormat(
          addMonths(checkDate, parseInt(recallDateInput.dataset.monthsToNextRecallDate))
        )
        toggleValueClass(recallDateInput)
      }
    }
  }
}

function initializePrescriptionRecallDate() {
  const container = document.querySelector('.js-prescription-recall-date-for-check-date')
  if (container) {
    prescriptionRecallDateForCheckDate(container)
  }
}

document.addEventListener('turbo:load', initializePrescriptionRecallDate)
document.addEventListener('turbo:render', initializePrescriptionRecallDate)
document.addEventListener('turbo:frame-render', initializePrescriptionRecallDate)

export { prescriptionRecallDateForCheckDate }
