import { isPreviewRender } from '../lib/helpers'

async function loadEditorFields(event) {
  if (isPreviewRender()) {
    // we don't want to setup Editor.js when we're rendering a cached
    // page. We'll wait for the updated (non-cached) page to render.
    return
  }

  await Promise.all([
    loadEditorField(event, '.js-editorjs-form-field', (element, EditorField) => EditorField.attach(element)),
    loadEditorField(event, '.js-minimal-editorjs-form-field', (element, EditorField) =>
      EditorField.attach(element, { minimal: true })
    ),
  ])
}

async function loadEditorField(event, css, attach) {
  const editorFormFields = event.target.querySelectorAll(css)

  if (editorFormFields.length > 0) {
    const { default: EditorField } = await import(
      /* webpackChunkName: "editor-field" */
      '../lib/EditorField'
    )

    editorFormFields.forEach((editorFormField) => {
      attach(editorFormField, EditorField)
    })
  }
}

document.addEventListener('starfish:render', loadEditorFields)
