// Allows setting "turbo-reload-after-milliseconds" data attribute on Turbo Frame to reload it after a period of time

import { Turbo } from '@hotwired/turbo-rails'

function turboReloadAfterMilliseconds() {
  const reloadElement = document.querySelector('[data-turbo-reload-after-milliseconds]')

  if (reloadElement) {
    setTimeout(function () {
      Turbo.visit(window.location.toString(), { action: 'replace' })
    }, reloadElement.dataset.turboReloadAfterMilliseconds)
  }
}

window.addEventListener('turbo:load', turboReloadAfterMilliseconds)
