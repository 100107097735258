// Google Analytics initialization and Enhanced Ecommerce tracking events

window.dataLayer = window.dataLayer || []
function gtag() {
  // eslint-disable-next-line no-undef
  dataLayer.push(arguments)
}
gtag('js', new Date())

function initializeConfig(trackingId, clientConversionId, event) {
  gtag('config', trackingId, { page_location: event.detail.url })
  if (clientConversionId) {
    gtag('config', clientConversionId, { page_location: event.detail.url })
  }
}

function productDetails(productSchema) {
  let schema = {
    id: productSchema.identifier,
    name: productSchema.name,
    category: productSchema.category,
    price: productSchema.offers.price,
  }

  if (productSchema.brand) {
    schema.brand = productSchema.brand.name
  }

  return schema
}

function activeCheckoutStepDetails() {
  const activeCheckoutStep = document.querySelector('.CheckoutStep[data-active="true"]')
  if (activeCheckoutStep) {
    return {
      step: Number(activeCheckoutStep.dataset.step),
      option: activeCheckoutStep.dataset.option,
    }
  }
}

function productListWithPosition(itemListElement) {
  return itemListElement.map((item, index) => {
    return Object.assign(productDetails(item.item), { list_position: index + 1 })
  })
}

function firstProductCurrency(jsonLd) {
  if (jsonLd['@type'] === 'ItemList') {
    return jsonLd.itemListElement[0].item.offers.priceCurrency
  }
  if (jsonLd['@type'] === 'Product') {
    return jsonLd.offers.priceCurrency
  }
}

function triggerPaymentEvent(jsonLd) {
  const orderId = document.querySelector('[data-content="order_id"]').innerText
  const shippingElement = document.querySelector('[data-content="shipping"]')

  const salesTaxElem = document.querySelector('[data-content="sales_tax"]')
  const orderTotal = document.querySelector('[data-content="order_total"]').innerText.substring(1)

  const payload = {
    transaction_id: orderId,
    value: orderTotal,
    currency: firstProductCurrency(jsonLd),
    items: productListWithPosition(jsonLd.itemListElement),
  }

  if (shippingElement) {
    payload.shipping = shippingElement.innerText.substring(1)
  }

  if (salesTaxElem) {
    const salesTax = salesTaxElem.innerText.substring(1)
    payload.tax = salesTax
  }

  gtag('event', 'purchase', payload)
}

function triggerPageLoadEvents(jsonLd, eventTrigger, platformTrackingId, clientTrackingId, clientConversionId) {
  // Events that depend on ItemList JSON-LD
  if (jsonLd['@type'] === 'ItemList') {
    // Measure product list impressions
    if (eventTrigger === 'view_item_list') {
      gtag('event', eventTrigger, { items: productListWithPosition(jsonLd.itemListElement) })
    }

    // Measure checkout steps
    if (eventTrigger === 'checkout_progress') {
      gtag('event', eventTrigger, {
        checkout_step: activeCheckoutStepDetails().step,
        checkout_option: activeCheckoutStepDetails().option,
        currency: firstProductCurrency(jsonLd),
        items: productListWithPosition(jsonLd.itemListElement),
      })
    }

    // Measure purchases
    if (eventTrigger === 'purchase') {
      triggerPaymentEvent(jsonLd)
    }
  }

  // Events that depend on Product JSON-LD
  if (jsonLd['@type'] === 'Product') {
    // Measure product detail view impressions
    if (eventTrigger === 'view_item') {
      gtag('event', eventTrigger, { items: [productDetails(jsonLd)] })
    }

    // Measure additions to cart
    if (eventTrigger === 'add_to_cart') {
      gtag('event', eventTrigger, {
        send_to: [platformTrackingId, clientTrackingId, clientConversionId],
        currency: firstProductCurrency(jsonLd),
        items: [productDetails(jsonLd)],
      })
      // Google Ads "Add to Cart" Conversion Action event
      const addToCartConversionLabel = document.body.dataset.gaAddToCartConversionActionLabel
      if (clientConversionId && addToCartConversionLabel) {
        gtag('event', 'conversion', {
          send_to: `${clientConversionId}/${addToCartConversionLabel}`,
          currency: firstProductCurrency(jsonLd),
          value: Number(jsonLd.offers.price),
        })
      }
    }
  }
}

function addClickEventHandlers(jsonLd, element) {
  // Events that depend on ItemList JSON-LD
  if (jsonLd['@type'] === 'ItemList') {
    // Measure checkout starts
    const beginCheckoutBtns = element.querySelectorAll('.js-begin-checkout')
    if (beginCheckoutBtns) {
      beginCheckoutBtns.forEach((button) => {
        button.addEventListener('click', () => {
          gtag('event', 'begin_checkout', {
            currency: firstProductCurrency(jsonLd),
            items: productListWithPosition(jsonLd.itemListElement),
          })
        })
      })
    }

    // Measure Loqate address selections
    const addressSearchField = element.querySelector('[data-loqate-address-api-url]')
    if (addressSearchField) {
      addressSearchField.addEventListener('click', (event) => {
        if (event.target.classList.contains('Autocomplete__option')) {
          gtag('event', 'address_picked')
        }
      })
    }

    // Measure prescription photo file selections
    const prescriptionPhotoInputElem = element.querySelector('.js-prescription-photo-input')
    if (prescriptionPhotoInputElem) {
      prescriptionPhotoInputElem.addEventListener('change', () => {
        if (prescriptionPhotoInputElem.files.length) {
          gtag('event', 'prescription_photo_attached')
        }
      })
    }
  }
}

function productOrItemListJsonLd(jsonLdElem) {
  const jsonLd = JSON.parse(jsonLdElem.innerText)

  return jsonLd['@graph'].find((object) => {
    return object['@type'] === 'ItemList' || object['@type'] === 'Product'
  })
}

document.addEventListener('turbo:load', (event) => {
  const platformScriptElem = document.querySelector('[data-platform-tracking-id]')
  const clientScriptElem = document.querySelector('[data-client-tracking-id]')
  const trackingEnabled = platformScriptElem || clientScriptElem
  let platformTrackingId, clientTrackingId, clientConversionId
  if (trackingEnabled) {
    // Initialize platform and client tracking
    if (platformScriptElem) {
      platformTrackingId = platformScriptElem.dataset.platformTrackingId
      initializeConfig(platformTrackingId, null, event)
    }
    if (clientScriptElem) {
      clientTrackingId = clientScriptElem.dataset.clientTrackingId
      clientConversionId = clientScriptElem.dataset.clientConversionId
      if (clientConversionId) {
        clientConversionId = `AW-${clientConversionId}`
      }
      initializeConfig(clientTrackingId, clientConversionId, event)
    }

    // Events depend on JSON-LD data present on the page
    const jsonLdElem = document.querySelector('script[type="application/ld+json"]')
    if (jsonLdElem) {
      const jsonLd = productOrItemListJsonLd(jsonLdElem)
      if (jsonLd) {
        // Trigger events based on data-ga-event when page loads
        const eventTrigger = document.body.dataset.gaEvent
        if (eventTrigger) {
          triggerPageLoadEvents(jsonLd, eventTrigger, platformTrackingId, clientTrackingId, clientConversionId)
        }

        // Trigger events on click based on JS classes
        addClickEventHandlers(jsonLd, document.body)
      }
    }
  }
})

document.addEventListener('turbo:frame-render', addClickEventHandlersForJsonLdElement)
document.addEventListener('turbo:render', addClickEventHandlersForJsonLdElement)

function addClickEventHandlersForJsonLdElement(event) {
  const jsonLdElem = document.querySelector('script[type="application/ld+json"]')

  if (jsonLdElem) {
    const jsonLd = productOrItemListJsonLd(jsonLdElem)
    if (jsonLd) {
      addClickEventHandlers(jsonLd, event.target)
    }
  }
}
