import { Controller } from '@hotwired/stimulus'

export default class SelectStoreController extends Controller {
  static targets = ['select', 'radio']

  selectChanged() {
    if (this.selectTarget.value) {
      this.radioTarget.checked = true
    }
  }
}
