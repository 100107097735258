import { Controller } from '@hotwired/stimulus'
import { hide, show } from '../../lib/helpers'

export default class TabsController extends Controller {
  static targets = ['tab', 'tabPanel', 'callback']
  static values = { defaultTab: String }

  onTabChange(event) {
    this.#handleTabChange(event.currentTarget)
  }

  connect() {
    if (this.hasDefaultTabValue) {
      const defaultTab = document.getElementById(this.defaultTabValue)
      this.#handleTabChange(defaultTab)
    }
  }

  #handleTabChange(clickedTab) {
    this.tabTargets.forEach((tab) => tab.setAttribute('aria-selected', 'false'))
    clickedTab.setAttribute('aria-selected', 'true')

    this.tabPanelTargets.forEach((tabPanel) => hide(tabPanel))
    const tabPanel = document.getElementById(clickedTab.getAttribute('aria-controls'))
    if (tabPanel) {
      show(tabPanel)
    }
    if (this.hasCallbackTarget) {
      this.callbackTarget.value = clickedTab.dataset.callbackValue
    }
  }
}
