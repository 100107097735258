// TODO: consider/look into using turbo streams for this

document.addEventListener('turbo:load', function () {
  document.querySelectorAll('.js-modal-complete-setup-services-form').forEach(function (form) {
    const button = form.querySelector('.js-modal-complete-setup-services-button')
    button.addEventListener('click', function () {
      const modalId = button.getAttribute('aria-controls')
      const modal = document.querySelector(`#${modalId} dialog`)

      showUncheckedServices(form, modal)

      modal.showModal()
    })
  })
})

function showUncheckedServices(form, modal) {
  const uncheckedServicesLabels = []

  const checkboxes = form.querySelectorAll('.FormField-checkInput')

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      uncheckedServicesLabels.push(checkbox.parentElement.querySelector('.FormField-checkLabel').innerText)
    }
  })

  const ul = modal.querySelector('.unchecked-services')
  ul.innerHTML = ''

  uncheckedServicesLabels.forEach((service) => {
    const li = document.createElement('li')
    li.classList.add('u-textBold')
    li.textContent = service
    ul.appendChild(li)
  })
}
