import { disableButton } from '../lib/helpers'

function setUpForm() {
  const form = document.querySelector('.js-iframeAddCardForm')
  if (form && !form.classList.contains('js-iframeAddCardForm-ready')) {
    addWindowEventListeners()
    const addCardButton = form.querySelector('.js-iframeAddCardForm-addCardButton')
    disableButton(addCardButton)
    form.classList.add('js-iframeAddCardForm-ready')
  }
}

function addWindowEventListeners() {
  window.addEventListener('message', windowMessageHandler, false)
  window.addEventListener('click', windowClickHandler, false)
}

function removeWindowEventListeners() {
  window.removeEventListener('message', windowMessageHandler)
  window.removeEventListener('click', windowClickHandler)
}

function windowMessageHandler(e) {
  if (e.data && typeof e.data === 'string' && e.data !== 'undefined') {
    const token = JSON.parse(e.data).message
    document.querySelector('#obfuscated_card_number').value = token
    document.querySelector('#token').value = token
    if (token) {
      const addCardButton = document.querySelector('.js-iframeAddCardForm-addCardButton')
      addCardButton.disabled = false
      addCardButton.classList.remove('is-disabled')
      if (addCardButton.clickRequested) {
        addCardButton.form.submit()
      }
    }
  }
}

function windowClickHandler(e) {
  if (e.target.classList.contains('js-iframeAddCardForm-addCardButton')) {
    e.target.clickRequested = true
  }
}

window.addEventListener('turbo:before-render', removeWindowEventListeners)
window.addEventListener('turbo:load', setUpForm)
