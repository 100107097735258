// Generic utility for toggling container visibility with a checkbox

import { toggle } from '../lib/helpers'

function checkboxToggle(e) {
  const containers = e.querySelectorAll('[data-visible-when-checked],[data-visible-when-unchecked]')

  containers.forEach((container) => {
    addEventListenerToCheckbox(container, container.dataset.visibleWhenChecked, (checkbox) => checkbox.checked)
    addEventListenerToCheckbox(container, container.dataset.visibleWhenUnchecked, (checkbox) => !checkbox.checked)
  })
}

function addEventListenerToCheckbox(container, checkboxId, shouldShow) {
  if (checkboxId) {
    const checkbox = document.getElementById(checkboxId)

    if (checkbox) {
      // toggle to initialize correct state
      toggle(container, shouldShow(checkbox)) // TODO: remove this when fully migrated to turbo
      checkbox.addEventListener('change', () => toggle(container, shouldShow(checkbox)))
    }
  }
}

document.addEventListener('turbo:load', (e) => {
  checkboxToggle(e.target)
})

document.addEventListener('turbo:render', (e) => {
  checkboxToggle(e.target)
})

export default checkboxToggle
