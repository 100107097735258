// Sets the "Title" and "Slug" fields from the selected Location or Staff Member

import { slugFromTitle } from '../lib/helpers'

function cmsPageForm() {
  if (document.querySelector('.js-cms-page-form')) {
    const titleInput = document.getElementById('page_title')
    const slugInput = document.getElementById('page_slug')
    const titleSettingInput = document.getElementById('page_location_id')
    if (titleSettingInput) {
      titleSettingInput.addEventListener('change', () =>
        inferTitleFromOtherInput(titleSettingInput, titleInput, slugInput)
      )
    }
  }
}

function inferTitleFromOtherInput(titleSettingInput, titleInput, slugInput) {
  const selectedOption = titleSettingInput.options[titleSettingInput.selectedIndex]
  if (selectedOption) {
    titleInput.value = selectedOption.innerHTML
    slugInput.value = slugFromTitle(titleInput.value)
  }
}

document.addEventListener('turbo:load', cmsPageForm)
