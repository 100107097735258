// Shows an error alert when a Turbo interaction happens when no internet connection

import { show } from '../lib/helpers'

document.querySelectorAll('form').forEach((form) => handleNoNetworkError(form))
document.querySelectorAll('turbo-frame').forEach((frame) => handleNoNetworkError(frame))

function handleNoNetworkError(element) {
  element.addEventListener('turbo:fetch-request-error', function () {
    show(document.querySelectorAll('.js-no-network-flash')[0])
  })
}
