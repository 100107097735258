import { hide, show, disableInput, enableInput, disableButton } from '../lib/helpers'
import { fetchJson } from '../lib/fetch_json'

let jobStatusInterval

window.addEventListener('turbo:before-fetch-request', function (event) {
  const container = document.querySelector('.js-terminalAddCardForm')
  if (!container) return

  // TODO: bit nuts, make the form into the container
  const form = container.querySelector('input').form

  if (event.target !== form) return

  // If we have a jobStatusInterval, it means we have created a job and that job has finished,
  // therefore we have nothing left to do, allow the remote form to actually submit (do not preventDefault)

  if (jobStatusInterval) {
    clearInterval(jobStatusInterval)
    jobStatusInterval = undefined
    return
  }

  event.preventDefault()
  const terminalErrorAlert = container.querySelector('.js-readCardFromTerminalError')
  hide(terminalErrorAlert)

  const submitButton = container.querySelector('.js-iframeAddCardForm-addCardButton')
  const terminalId = container.querySelector('.js-terminalId').value
  disableButton(submitButton)

  const cardReadingsUrl = container.dataset.terminalCardReadingsUrl
  disableFormFields()
  createCardReading(cardReadingsUrl, terminalId)

  async function fetchJobUpdates(cardReadingUrl, done) {
    const body = await fetchJson(cardReadingUrl)
    if (body.status === 'done') {
      done(body)
    }
  }

  async function createCardReading(cardReadingsUrl, terminalId) {
    const createCardReadingResponseBody = await fetchJson(cardReadingsUrl, {
      method: 'POST',
      body: JSON.stringify({ terminal_id: terminalId }),
    })

    jobStatusInterval = setInterval(function () {
      fetchJobUpdates(createCardReadingResponseBody.url, function done(body) {
        const addCardButton = container.querySelector('.js-iframeAddCardForm-addCardButton')
        addCardButton.disabled = false
        addCardButton.classList.remove('is-disabled')

        const readCardResponse = JSON.parse(body.read_card_response)
        const { error } = readCardResponse

        enableFormFields()
        if (error) {
          clearInterval(jobStatusInterval)
          showError(error)
        } else {
          updateCardFieldsWithResponse(readCardResponse)
          form.requestSubmit()
        }
      })
    }, 1000)
  }

  function showError(error) {
    const terminalErrorAlert = container.querySelector('.js-readCardFromTerminalError')
    show(terminalErrorAlert)
    terminalErrorAlert.querySelector('p').innerText = error
  }

  function updateCardFieldsWithResponse(read_card_response) {
    const { obfuscated_card_number, short_expiry_year, expiry_month } = read_card_response
    container.querySelector('.js-obfuscatedCardNumber').value = obfuscated_card_number
    container.querySelector('.js-token').value = obfuscated_card_number
    container.querySelector('.js-expiryYear').value = short_expiry_year
    container.querySelector('.js-expiryMonth').value = expiry_month
  }

  function disableFormFields() {
    const inputs = Array.from(form.querySelectorAll('input, button, select, textarea'))
    inputs.forEach((input) => disableInput(input))
  }

  function enableFormFields() {
    const inputs = Array.from(form.querySelectorAll('input, button, select, textarea'))
    inputs.forEach((input) => enableInput(input))
  }
})
