import { fetchJson } from './fetch_json'

class LoqateApi {
  constructor({ key, url, countries }) {
    this._key = key
    this._countries = countries || []
    this._url = url
    if (url.indexOf('http') === -1) {
      this._url = `${window.location.origin}${url}`
    }
    this._controller = new AbortController()
  }

  url(action) {
    return this._url.replace('$ACTION', action)
  }

  async suggest(query, populateResults) {
    this._controller.abort()
    this._controller = new AbortController()
    try {
      const suggestions = await this.searchAddresses(query)
      populateResults(suggestions)
    } catch (e) {
      if (!(e instanceof DOMException && e.name === 'AbortError')) {
        throw e
      }
    }
  }

  async searchAddresses(query) {
    const response = await this.fetchJson('Find', {
      params: { Text: query, Countries: this._countries },
    })
    return response.Items.filter((item) => item.Type === 'Address')
  }

  async getAddress(address) {
    if (!address) {
      return
    }
    const addressId = address.Id

    const response = await this.fetchJson('Retrieve', {
      params: { Id: addressId },
    })
    const fullPrediction = response.Items[0]

    const fullAddress = {
      address1: fullPrediction.Line1,
      address2: fullPrediction.Line2,
      address3: fullPrediction.Line3,
      city: fullPrediction.City,
      region_code: fullPrediction.Province,
      postal_code: fullPrediction.PostalCode,
      country_code: fullPrediction.CountryIso2,
    }
    return fullAddress
  }

  fetchJson(action, options) {
    options.params.Key = this._key
    options.params.Countries = this._countries
    const apiUrl = new URL(this.url(action))
    apiUrl.search = new URLSearchParams(options.params)
    return fetchJson(apiUrl, { signal: this._controller.signal })
  }
}

export default LoqateApi
