import { Controller } from '@hotwired/stimulus'
import { hide, show } from '../../lib/helpers'

export default class OtherReasonController extends Controller {
  static targets = ['selectedInput', 'selectedOutput']
  static values = { showIf: Array }

  connect() {
    this.showOrHide()
  }

  showOrHide() {
    const otherReasonsId = this.showIfValue.map((t) => `${t.toString()}`)

    if (otherReasonsId.indexOf(this.selectedInputTarget.value) === -1) {
      hide(this.selectedOutputTarget)
    } else {
      show(this.selectedOutputTarget)
    }
  }
}
