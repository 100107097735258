import { Controller } from '@hotwired/stimulus'
import { slugFromTitle, toggleValueClass } from '../../lib/helpers'

export default class SlugFromTitleController extends Controller {
  static targets = ['title', 'slug']

  updateSlug() {
    const titleInput = this.titleTarget
    const slugInput = this.slugTarget

    slugInput.value = slugFromTitle(titleInput.value)
    toggleValueClass(slugInput)
  }
}
