// Generic utility for showing a form's submit button when an input is inputed

import { show } from '../lib/helpers'

function showSubmitOnInput() {
  const inputs = document.querySelectorAll('.js-show-submit-on-input')

  inputs.forEach((input) => {
    const formSubmitButton = input.form.querySelector('button[type="submit"]')

    input.addEventListener('input', () => show(formSubmitButton))
  })
}

document.addEventListener('turbo:load', showSubmitOnInput)

export default showSubmitOnInput
