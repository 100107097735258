// Generic utility for adding or removing `required` attribute based on visibility
// to prevent issues when form is submitted when required input is hidden
function addOrRemoveRequired(requiredInputs) {
  requiredInputs.forEach((input) => {
    if (input) {
      if (input.closest('.u-hidden')) {
        if (input.required) {
          input.required = false
        }
      } else {
        if (!input.required) {
          input.required = true
        }
      }
    }
  })
}

function requiredInput() {
  const requiredInputs = document.querySelectorAll(
    'input[required]:not([data-disable-hidden-required]), select[required], textarea[required]'
  )
  if (requiredInputs.length) {
    addOrRemoveRequired(requiredInputs)
    document.addEventListener('change', () => addOrRemoveRequired(requiredInputs))
  }
}

document.addEventListener('turbo:load', requiredInput)
document.addEventListener('turbo:render', requiredInput)

export default requiredInput
