// "Same Rx for both eyes" checkbox on product page and add prescription page.
// Copies existing values when checked and copies values on select change

import { toggleEnabled, disableInput } from '../lib/helpers'

function copyProduct(rightEyeProductInput, leftEyeProductInput, sameRxCheckbox) {
  if (rightEyeProductInput && leftEyeProductInput) {
    if (rightEyeProductInput.value !== leftEyeProductInput.value) {
      if (rightEyeProductInput.value) {
        leftEyeProductInput.value = rightEyeProductInput.value
        leftEyeProductInput.dispatchEvent(new CustomEvent('change'))
      } else if (leftEyeProductInput.value) {
        rightEyeProductInput.value = leftEyeProductInput.value
        rightEyeProductInput.dispatchEvent(new CustomEvent('change'))
      }
    }

    toggleEnabled(leftEyeProductInput, !sameRxCheckbox.checked)
  }
}

function copyMetrics(rightEyeMetricInput, leftEyeMetricInput, sameRxCheckbox) {
  if (rightEyeMetricInput.value) {
    leftEyeMetricInput.value = rightEyeMetricInput.value
    leftEyeMetricInput.dispatchEvent(new CustomEvent('change'))
  } else if (leftEyeMetricInput.value) {
    rightEyeMetricInput.value = leftEyeMetricInput.value
    rightEyeMetricInput.dispatchEvent(new CustomEvent('change'))
  }

  toggleEnabled(leftEyeMetricInput, !sameRxCheckbox.checked)
}

function sameRx() {
  const sameRxCheckbox = document.querySelector('.js-same-rx-checkbox')
  const rightEyeProductInput = document.querySelector('.js-right-eye-product')
  const leftEyeProductInput = document.querySelector('.js-left-eye-product')
  const rightEyeMetricInputs = document.querySelectorAll('.js-right-eye-metric')
  const leftEyeMetricInputs = document.querySelectorAll('.js-left-eye-metric')

  if (sameRxCheckbox) {
    leftEyeMetricInputs.forEach((leftEyeMetricInput) => {
      const rightEyeMetricInput = document.querySelector(`[name="${leftEyeMetricInput.name.replace('left', 'right')}"]`)

      // Disable Left Eye product and metrics (on load) if already checked
      if (sameRxCheckbox.checked) {
        if (leftEyeProductInput) {
          disableInput(leftEyeProductInput)
        }
        disableInput(leftEyeMetricInput)
      }

      // Copy product and metrics (when Same Rx checked)
      sameRxCheckbox.addEventListener('change', () => {
        copyProduct(rightEyeProductInput, leftEyeProductInput, sameRxCheckbox)
        copyMetrics(rightEyeMetricInput, leftEyeMetricInput, sameRxCheckbox)
      })
    })

    // Copy Right Eye product (on change) to Left Eye
    if (rightEyeProductInput && leftEyeProductInput) {
      rightEyeProductInput.addEventListener('change', () => {
        if (sameRxCheckbox.checked) {
          leftEyeProductInput.value = rightEyeProductInput.value
          leftEyeProductInput.dispatchEvent(new CustomEvent('change'))
        }
      })
    }

    // Copy Right Eye metrics (on change) to Left Eye
    rightEyeMetricInputs.forEach((rightEyeMetric) => {
      rightEyeMetric.addEventListener('change', () => {
        if (sameRxCheckbox.checked) {
          const leftEyeMetricInput = document.querySelector(`[name="${rightEyeMetric.name.replace('right', 'left')}"]`)
          leftEyeMetricInput.value = rightEyeMetric.value
          leftEyeMetricInput.dispatchEvent(new CustomEvent('change'))
        }
      })
    })
  }
}

document.addEventListener('starfish:render', sameRx)

export default sameRx
