// Generic utility for expanding a container

import { hide, show } from '../lib/helpers'

function expandable() {
  document.querySelectorAll('.js-expandable').forEach(function (container) {
    const expander = container.querySelector('.js-expandable-expander')
    show(expander)
    expander.addEventListener('click', function () {
      container.querySelector('.js-expandable-content').classList.toggle('js-expandable-content')
      hide(expander)
    })
  })
}

document.addEventListener('turbo:load', expandable)
export default expandable
