import { Controller } from '@hotwired/stimulus'

export default class FileFieldController extends Controller {
  static targets = ['input', 'label']

  updateLabel() {
    const defaultLabel = this.labelTarget.textContent
    if (this.inputTarget.files.length) {
      return (this.labelTarget.textContent = this.inputTarget.files[0].name)
    }
    this.labelTarget.textContent = defaultLabel
  }
}
