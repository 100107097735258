import { Controller } from '@hotwired/stimulus'
import { hide, show } from '../../lib/helpers'

// Countries for which we should not show a state field
const COUNTRY_CODES_WITH_NO_STATE_FIELD = ['NL']

export default class AddressController extends Controller {
  static targets = ['countryCode', 'regionCode']

  connect() {
    this.countryChanged()
  }

  countryChanged() {
    if (COUNTRY_CODES_WITH_NO_STATE_FIELD.includes(this.countryCodeTarget.value)) {
      hide(this.regionCodeTarget.parentNode)
    } else {
      show(this.regionCodeTarget.parentNode)
    }
  }
}
