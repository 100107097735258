import Rails from '@rails/ujs'
import { disableButton, enableButton } from '../lib/helpers'

// This makes button links look consistent to form buttons when in "loading" state
document.addEventListener('turbo:click', ({ target }) => {
  target.classList.add('is-disabled')

  const reEnable = function () {
    Rails.enableElement(target)
    target.classList.remove('is-disabled')

    document.removeEventListener('turbo:before-cache', reEnable)
  }

  document.addEventListener('turbo:before-cache', reEnable)
})

// Sometimes there is more than one submit button on the page.
// Turbo disables the one that's been clicked. We need to disable the rest.
document.addEventListener('turbo:submit-start', ({ detail: { formSubmission } }) => {
  const form = formSubmission.formElement
  const enabledSubmitButtons = document.querySelectorAll('button[type=submit]:enabled')

  enabledSubmitButtons.forEach(disableButton)

  const reEnable = function ({ detail: { formSubmission } }) {
    if (formSubmission.formElement === form) {
      enabledSubmitButtons.forEach(enableButton)
      document.removeEventListener('turbo:submit-end', reEnable)
    }
  }

  document.addEventListener('turbo:submit-end', reEnable)
})
