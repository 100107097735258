import { Controller } from '@hotwired/stimulus'

export default class SelectAllController extends Controller {
  static targets = ['selectAll', 'checkbox']

  connect() {
    this.updateSelectAllState()
  }

  selectAllTargetConnected(newSelectAll) {
    newSelectAll.checked = this.selectAllTarget.checked
  }

  checkboxTargetConnected(newCheckbox) {
    newCheckbox.checked = this.selectAllTarget.checked
  }

  updateSelectAllState() {
    if (this.checkboxTargets.length) {
      const allChecked = this.checkboxTargets.every((checkbox) => checkbox.checked)
      const anyChecked = this.checkboxTargets.some((checkbox) => checkbox.checked)
      this.selectAllTargets.forEach((selectAll) => {
        selectAll.checked = allChecked
        selectAll.indeterminate = anyChecked && !allChecked
      })
    }
  }

  toggleAll(event) {
    const checked = event.target.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked
    })
    this.updateSelectAllState()
  }

  checkState() {
    this.updateSelectAllState()
  }
}
