// Load re-captcha onto forms that have requested it

import { load } from 'recaptcha-v3'
import { disableButton } from '../lib/helpers'

function enableRecaptcha(e, loadCaptcha) {
  const tokenField = document.querySelector('form .js-captcha-token')
  if (tokenField) {
    loadCaptcha(tokenField.dataset.captchaPublicKey, { autoHideBadge: true }).then((recaptcha) => {
      tokenField.form.addEventListener('submit', async (e) => {
        e.preventDefault()
        await submitWithCaptcha(e.target, recaptcha)
      })
    })
  }
}

async function submitWithCaptcha(form, recaptcha) {
  disableButton(form.querySelector("button[type='submit']"))
  const token = await recaptcha.execute('submit')
  form.querySelector('.js-captcha-token').value = token
  form.submit()
}

document.addEventListener('turbo:load', (e) => {
  enableRecaptcha(e.target, load)
})

export default enableRecaptcha
