// Intercept page anchor links and scroll to them

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('a').forEach(async (anchor) => {
    const href = anchor.getAttribute('href')
    if (href && href.length > 1 && href[0] === '#' && !anchor.classList.contains('js-tabs-item')) {
      const target = document.querySelector(href)
      const { default: scrollIntoView } = await import('scroll-into-view-if-needed')

      anchor.addEventListener('click', (e) => {
        e.preventDefault()
        scrollIntoView(target, { block: 'start', behavior: 'smooth' })
      })
    }
  })
})
