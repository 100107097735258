import { Controller } from '@hotwired/stimulus'

export default class MandateSignatureValidationController extends Controller {
  static targets = ['termsOfService', 'signature', 'signMandate']

  connect() {
    this.updateButtonState()
  }

  updateButtonState() {
    const termsOfServiceAgreed = this.termsOfServiceTarget.checked
    const signed = this.signatureTarget.value.trim().length > 0

    this.signMandateTarget.disabled = !(termsOfServiceAgreed && signed)
  }
}
