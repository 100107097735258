export const addressFields = ['name', 'address1', 'address2', 'city', 'postal_code', 'region_code', 'country']

export function createSelectOption(text, value) {
  const option = document.createElement('option')
  option.textContent = text
  option.value = value
  return option
}

function element(selectorOrElement) {
  if (typeof selectorOrElement === 'string') {
    return document.querySelector(selectorOrElement)
  }
  return selectorOrElement
}

export function hide(selectorOrElement) {
  const el = element(selectorOrElement)
  el.classList.add('u-hidden')

  if (el.classList.contains('js-disable-when-hidden')) {
    el.querySelectorAll('input, select, textarea').forEach((input) => disableInput(input))
  }
}

export function show(selectorOrElement) {
  const el = element(selectorOrElement)
  el.classList.remove('u-hidden')

  if (el.classList.contains('js-disable-when-hidden')) {
    el.querySelectorAll('input, select, textarea').forEach((input) => enableInput(input))
  }
}

export function toggle(container, shouldShow) {
  if (shouldShow) {
    return show(container)
  }
  hide(container)
}

export function brandColorPrimary() {
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--colorPrimary')
  if (primaryColor) {
    return primaryColor.trim()
  }
}

export function brandColorSecondary() {
  const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--colorSecondary')
  if (secondaryColor) {
    return secondaryColor.trim()
  }
}

export function hideIfExists(selector) {
  const element = document.querySelector(selector)
  if (element) {
    element.classList.add('u-hidden')
  }
}

export function disableInput(input) {
  input.disabled = true

  const formField = input.closest('.FormField')
  if (formField) {
    formField.classList.add('is-disabled')
  }
}

export function enableButton(button) {
  if (button.dataset.ignore !== 'true') {
    button.disabled = false
    button.classList.remove('is-disabled')
  }
}

export function disableButton(button) {
  if (button.dataset.ignore !== 'true') {
    button.disabled = true
    button.classList.add('is-disabled')
  }
}

export function enableInput(input) {
  input.disabled = false

  const formField = input.closest('.FormField')
  if (formField) {
    formField.classList.remove('is-disabled')
  }
}

export function toggleEnabled(input, enabled) {
  if (enabled) {
    enableInput(input)
  } else {
    disableInput(input)
  }
}

export function readonlyInput(input) {
  input.readOnly = true

  const formField = input.closest('.FormField')
  if (formField) {
    formField.classList.add('is-readonly')
  }
}

export function writableInput(input) {
  input.readOnly = false

  const formField = input.closest('.FormField')
  if (formField) {
    formField.classList.remove('is-readonly')
  }
}

export function requireInput(input, required) {
  input.required = required

  const formField = input.closest('.FormField')

  if (formField) {
    if (required) {
      formField.classList.add('FormField--required')
    } else {
      formField.classList.remove('FormField--required')
    }
  }
}

export function loadScript(src) {
  return new Promise((resolve, reject) => {
    var s = document.createElement('script')
    s.setAttribute('src', src)
    s.addEventListener('load', resolve)
    s.addEventListener('error', reject)
    document.body.appendChild(s)
  })
}

export function slugFromTitle(title) {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, ' ')
    .trim()
    .replace(/\s+/g, '-')
}

export function interpolate(template, params) {
  return template.replace(/\\?%{([a-z_][a-z_0-9]*)}/gi, (placeholder, paramName) => {
    if (placeholder.startsWith('\\')) {
      return placeholder.substring(1)
    } else {
      return params[paramName]
    }
  })
}

export function isPreviewRender() {
  return document.documentElement.hasAttribute('data-turbo-preview')
}

/**
 * Add 'http' protocol to URL values like 'google.com'
 */
export function addProtocolToURL(url) {
  if (url.trim() === '') return url

  // If protocol already exists, do nothing
  if (/^(\w+):(\/\/)?/.test(url)) {
    return url
  }

  /**
   * Add HTTP protocol to the link, but skip these cases:
   * 1) Internal links like "/general"
   * 2) Anchor links like "#results"
   * 3) Protocol-relative URLs like "//google.com"
   */
  const isInternal = /^\/[^/\s]/.test(url)
  const isAnchor = url.substring(0, 1) === '#'
  const isProtocolRelative = /^\/\/[^/\s]/.test(url)

  if (!isInternal && !isAnchor && !isProtocolRelative) {
    url = 'http://' + url
  }

  return url
}

export function locale() {
  return location.pathname.split('/')[1]
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

export function addMonths(date, months) {
  const day = date.getDate()
  date.setMonth(date.getMonth() + months)
  if (date.getDate() !== day) {
    date.setDate(0)
  }
  return date
}

export function isoDateFormat(date) {
  return (
    String(date.getFullYear()) +
    '-' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(date.getDate()).padStart(2, '0')
  )
}

export function toggleValueClass(element) {
  if (element.value || element.readOnly) {
    return element.classList.add('has-value')
  }
  element.classList.remove('has-value')
}
