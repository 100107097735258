// Apply behavior to accordions

async function accordion() {
  const accordions = document.querySelectorAll('.js-accordion')

  if (accordions.length) {
    accordions.forEach((accordion) => {
      const sections = accordion.querySelectorAll('details')

      sections.forEach((section) => {
        // Collapse all other sections when heading clicked
        const heading = section.querySelector('summary')
        heading.addEventListener('click', () => {
          sections.forEach((otherSection) => {
            if (otherSection !== section) {
              otherSection.removeAttribute('open')
            }
          })
        })
      })
    })
  }
}

document.addEventListener('starfish:render', accordion)

export default accordion
