// Show or hide parts of prescription approval

import { hide, show } from '../lib/helpers'

document.addEventListener('turbo:load', () => {
  const rejectables = document.querySelectorAll('.js-prescriptionVerificationRejection')
  const approvables = document.querySelectorAll('.js-prescriptionVerificationApproval')

  findCorrectIncorrectRadios().forEach((radio) => {
    radio.addEventListener('change', showApproveOrRejectElements)
  })
  showApproveOrRejectElements()

  function showApproveOrRejectElements() {
    const incorrect = findCorrectIncorrectRadios().some((radio) => radio.checked && radio.value === 'incorrect')
    const hideables = incorrect ? approvables : rejectables
    const showables = incorrect ? rejectables : approvables

    hideables.forEach((hideable) => hide(hideable))

    showables.forEach((showable) => show(showable))
  }
})

function findCorrectIncorrectRadios() {
  return Array.from(document.querySelectorAll('input[type=radio][value=correct], input[type=radio][value=incorrect]'))
}
