import { hide, show } from '../lib/helpers'
import { waitForServerSideConfirmation, ResultDiv } from '../lib/iframe_card_fields'

window.addEventListener('message', async (event) => {
  if (event.origin !== window.location.origin) return

  const type = event.data && event.data.type
  if (type !== 'windcave') return

  hide('#windcave-iframe')

  const result = event.data.result
  const sessionId = event.data.sessionId
  const resultDiv = new ResultDiv()

  if (result === 'declined') {
    resultDiv.show('failure')
  } else if (result === 'cancelled') {
    resultDiv.show('error')
  } else if (result === 'approved') {
    resultDiv.show('progress')

    try {
      const confirmationUrl = `${location.origin}/windcave/notifications/${sessionId}`
      await waitForServerSideConfirmation(confirmationUrl)

      const hiddenField = document.querySelector('#card_session_id')
      hiddenField.value = sessionId

      hiddenField.form.submit()
    } catch (error) {
      resultDiv.show('error')
    }
  }
})

window.addEventListener('turbo:load', function () {
  const showiFrame = function () {
    hide('#js-card-iframe-loading')
    show(iframe)
  }

  const iframe = document.getElementById('windcave-iframe')
  if (!iframe) return

  /* This prevents a race condition where the iframe is already loaded so the
   * frame is never shown, which was happening in development. It's still possible
   * for this to happen if the iframe is loaded but the document isn't complete,
   * but that's a smaller window, and we haven't seen it happen yet. */
  if (document.readyState === 'complete') {
    showiFrame()
  } else {
    iframe.addEventListener('load', showiFrame)
  }
})

// Restore original html before navigating away from card page
window.addEventListener('turbo:before-cache', function () {
  const iframe = document.getElementById('windcave-iframe')

  if (iframe) {
    show('#js-card-iframe-loading')
    hide(iframe)
  }
})
