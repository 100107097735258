// Polls for in progress payments and submits the current form when completed or after a minute
import { fetchJson } from '../lib/fetch_json'

function pollForPaymentCompletion(inProgressPaymentForm) {
  const promise = isPaymentCompleted(inProgressPaymentForm.dataset.paymentUrl)
  promise.then(function (completed) {
    if (completed) {
      inProgressPaymentForm.submit()
    } else {
      setTimeout(function () {
        pollForPaymentCompletion(inProgressPaymentForm)
      }, 2500)
      setTimeout(function () {
        inProgressPaymentForm.submit()
      }, 30000)
    }
  })
}

async function isPaymentCompleted(paymentURl) {
  const { completed } = await fetchJson(paymentURl)
  return completed
}

document.addEventListener('turbo:load', () => {
  const inProgressPaymentForm = document.querySelector('.js-in-progress-payment')

  if (inProgressPaymentForm) {
    pollForPaymentCompletion(inProgressPaymentForm)
  }
})
