import { Controller } from '@hotwired/stimulus'

export default class ShipmentReturnIntentionEditController extends Controller {
  static targets = ['packagingUploads', 'submitButton']

  static values = { damagedShipmentCanBeReturned: Boolean }

  togglePackagingUploads(event) {
    let shouldHide = event.target.value !== 'package_damaged'
    this.packagingUploadsTarget.classList.toggle('u-hidden', shouldHide)
  }

  checkDamagedPackageCanBeReturned(event) {
    let isPackageDamaged = event.target.value === 'package_damaged',
      damagedPackageCanBeReturned = this.damagedShipmentCanBeReturnedValue

    this.submitButtonTarget.disabled = isPackageDamaged && !damagedPackageCanBeReturned
  }
}
