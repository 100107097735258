import { Turbo } from '@hotwired/turbo-rails'
import { fetchJson } from '../lib/fetch_json'

let interval
let shouldRedirectWhenPaid

// when the "Place Order" page loads, start polling to see if the order was paid elsewhere (e.g. QR code)
window.addEventListener('turbo:load', startPollingOnPayNowPage)

// but stop polling when I click away from that page
window.addEventListener('turbo:click', stopPolling)

// also stop polling when I navigate back in my browser history
window.addEventListener('turbo:before-render', stopPolling)

// also stop polling when I press "Place Order" (or submit any other form)
window.addEventListener('turbo:submit-start', stopPolling)

// but start polling again if "Place Order" failed
window.addEventListener('turbo:submit-end', function ({ detail }) {
  if (!detail.success) {
    startPollingOnPayNowPage()
  }
})

function startPollingOnPayNowPage() {
  const element = document.querySelector('.js-redirectToCheckoutConfirmationWhenPaid')
  if (element) {
    const checkoutConfirmationUrl = element.dataset.checkoutConfirmationUrl
    shouldRedirectWhenPaid = true
    interval = setInterval(function () {
      fetchJsonAndRedirectIfPaid(checkoutConfirmationUrl)
    }, 1000)
  } else {
    shouldRedirectWhenPaid = false
  }
}

async function fetchJsonAndRedirectIfPaid(checkoutConfirmationUrl) {
  const { paid } = await fetchJson(checkoutConfirmationUrl)
  if (paid && shouldRedirectWhenPaid) {
    stopPolling()
    Turbo.visit(checkoutConfirmationUrl)
  }
}

function stopPolling() {
  // stop making more "is it paid yet?" requests
  clearInterval(interval)
  // ignore responses to any in-flight "is it paid yet?" requests
  shouldRedirectWhenPaid = false
}
