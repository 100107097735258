// Platform Admin "Custom Reports" search form

document.addEventListener('turbo:load', () => {
  const customReportsForm = document.querySelector('.js-custom-reports-form')
  if (customReportsForm) {
    // Append #results to pagination links
    document.querySelectorAll('a.Pagination-itemLink').forEach((link) => {
      const href = link.getAttribute('href')
      if (!href.endsWith('#results')) {
        link.setAttribute('href', `${href}#results`)
      }
    })

    // Append hidden input to form if Save Report prompt used
    const saveButton = document.querySelector('.js-save-report-button')
    if (saveButton) {
      saveButton.addEventListener('click', (e) => {
        const reportNameLabel = saveButton.dataset.reportName
        const reportName = prompt(reportNameLabel)
        if (reportName) {
          var hiddenNameInput = document.createElement('input')
          hiddenNameInput.hidden = true
          hiddenNameInput.name = 'name'
          hiddenNameInput.value = reportName
          e.srcElement.form.appendChild(hiddenNameInput)
          return
        }
        e.preventDefault()
      })
    }
  }
})
