import { hide, show, enableInput } from '../lib/helpers'
// SAML configuration optional certificate replacement

function watchSamlCertificateButton() {
  document.querySelectorAll('.js-saml-configuration').forEach((element) => {
    element.querySelector('.js-saml-certificate-button').addEventListener('click', function () {
      var certificate_field = element.querySelector('.js-saml-cert-field')

      hide(this)
      enableInput(certificate_field)
      show(certificate_field)
    })
  })
}

document.addEventListener('turbo:load', () => {
  watchSamlCertificateButton()
})
