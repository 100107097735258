import { requireInput } from '../lib/helpers'

function requireImageAltsWhenImagePresent() {
  const inputFields = document.querySelectorAll('input[type=file]')

  inputFields.forEach((input) => {
    input.addEventListener('change', () => {
      const altField = document.querySelector(`#${input.id}_alt`)

      if (altField) {
        if (input.files.length > 0) {
          requireInput(altField, true)
        } else {
          requireInput(altField, false)
        }
      }
    })
  })
}

document.addEventListener('starfish:render', requireImageAltsWhenImagePresent)

export default requireImageAltsWhenImagePresent
