import storageAvailable from 'storage-available'

// Imports and then deletes the terminal id value from local storage which was used by slp1
document.addEventListener('turbo:load', () => {
  const terminalIdInput = document.querySelector('.js-terminalId')

  if (terminalIdInput && storageAvailable('localStorage')) {
    const storageTerminalId = window.localStorage.getItem('terminalId')

    if (storageTerminalId) {
      terminalIdInput.value = storageTerminalId
      window.localStorage.removeItem('terminalId')
      window.localStorage.setItem('remember-selection:terminal_id', storageTerminalId)
    }
  }
})
