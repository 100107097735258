// fetches JSON and returns the body.
// when the server responds with a 401, this causes the brower to reload the current URL (with turbo)
// which should cause a redirect to the sign in page

import { Turbo } from '@hotwired/turbo-rails'

export async function fetchJson(url, options = {}) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  })
  options.headers = headers
  options.dataType = 'json'
  const response = await fetch(url, options)
  if (response.status === 401) {
    Turbo.visit(window.location.href)
    return { status: 401 }
  } else {
    const body = await response.json()
    return body
  }
}
