// Generic utility for switching between containers with a radio group

import { hide, show } from '../lib/helpers'

function radioSwitcher() {
  const switchers = document.querySelectorAll('.js-radio-switcher')

  switchers.forEach((outerContainer) => {
    const contentContainers = outerContainer.querySelectorAll("[class^='js-radio-switcher-container']")
    const radioInputs = outerContainer.querySelectorAll('input[type=radio]')

    radioInputs.forEach((radio, index) => {
      radio.addEventListener('change', () => {
        contentContainers.forEach((container) => {
          if (container.classList.contains(`js-radio-switcher-container-${index + 1}`)) {
            return show(container)
          }
          hide(container)
        })
      })
    })
  })
}

document.addEventListener('starfish:render', radioSwitcher)

export default radioSwitcher
