// Updates a resource without embedding a form (e.g. when we are already in a form!)
// and reloads the current turbo-frame to reflect the update. For example, we use it
// when we are updating models with images, and we want to remove the existing image
// before attaching a new one

window.addEventListener('click', (event) => {
  if (event.target.matches('a.js-inline-update-link, a.js-inline-update-link *')) {
    event.preventDefault()

    const anchor = event.target.matches('a') ? event.target : event.target.closest('a')
    const frame = anchor.closest('turbo-frame')
    const confirmModal = frame.querySelector('.js-modal-intercept-form')
    if (confirmModal) {
      confirmModal.showModal()
      confirmModal.querySelector('.js-intercept-form-continue').addEventListener('click', () => {
        inlineUpdate(anchor, frame)
      })
    } else {
      inlineUpdate(anchor, frame)
    }
  }
})

async function inlineUpdate(anchor, frame) {
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Http-Method-Override': 'PATCH',
    'X-Inline-Update': 'true',
  }
  const csrfTokenMetaTag = document.querySelector('meta[name=csrf-token]')
  if (csrfTokenMetaTag) {
    headers['X-CSRF-Token'] = csrfTokenMetaTag.content
  }

  await fetch(anchor.href, { method: 'POST', headers: new Headers(headers) }) // don't do turbo nav (IGNORE THE REDIRECT!!)
  const closestFrameWithSrc = anchor.closest('turbo-frame[src]')
  if (closestFrameWithSrc) {
    frame.src = closestFrameWithSrc.src // this is a frame within a frame
  } else {
    frame.src = window.location.href // stay on the current URL
  }
}
