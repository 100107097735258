import { disableButton, enableButton } from '../lib/helpers'

function disabledButtonsOnFetchRequests() {
  const enabledSubmitButtons = document.querySelectorAll('button[type=submit]:enabled')

  enabledSubmitButtons.forEach(disableButton)
  const reEnable = () => enabledSubmitButtons.forEach(enableButton)

  document.addEventListener('turbo:frame-render', reEnable, { once: true })
  document.addEventListener('turbo:submit-end', reEnable, { once: true })
  document.addEventListener('turbo:before-cache', reEnable, { once: true })
}

document.addEventListener('turbo:before-fetch-request', disabledButtonsOnFetchRequests)

export default disabledButtonsOnFetchRequests
