// Consent to cookies and dismiss cookie bar

import { hide } from '../lib/helpers'

function setCookie() {
  const CookieDate = new Date()
  CookieDate.setFullYear(CookieDate.getFullYear() + 1)
  document.cookie = 'cookie_bar_dismissed=1; expires=' + CookieDate.toUTCString() + '; path=/'
}

function cookieConsent(container) {
  document.querySelector('.js-cookies-accept').addEventListener('click', () => {
    setCookie()
    hide(container)
  })
}

document.addEventListener('turbo:load', () => {
  const cookieConsentContainer = document.querySelector('.js-cookies-consent')
  if (cookieConsentContainer) {
    cookieConsent(cookieConsentContainer)
  }
})
