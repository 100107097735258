// Powers "purchase this eye" checkboxes next to metrics fields on product page. Disables
// metrics dropdowns for that eye when checked and disables "Same Rx for both eyes" checkbox

import { disableInput, enableInput } from '../lib/helpers'

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.js-purchase-eye-checkbox').forEach((checkbox) => {
    const eyeId = checkbox.dataset.eyeId

    if (eyeId) {
      const sameRxCheckbox = document.querySelector('.js-same-rx-checkbox')
      if (sameRxCheckbox) {
        checkbox.addEventListener('change', () => {
          if (!checkbox.checked) {
            const otherEye = eyeId === 'left_eye' ? 'right' : 'left'
            document.querySelector(`input[data-eye-id=${otherEye}_eye]`).checked = true
            document.querySelectorAll(`.js-${otherEye}-eye-metric`).forEach((input) => {
              enableInput(input)
            })
          }
        })
        const metricInputsSelector = `.js-${eyeId.replace('_eye', '')}-eye-metric`
        document.querySelectorAll(metricInputsSelector).forEach((input) => {
          if (!checkbox.checked) {
            sameRxCheckbox.checked = false
            disableInput(sameRxCheckbox)
            disableInput(input)
          }
          checkbox.addEventListener('change', () => {
            sameRxCheckbox.checked = false

            if (checkbox.checked) {
              enableInput(sameRxCheckbox)
              enableInput(input)
            } else {
              disableInput(sameRxCheckbox)
              disableInput(input)
            }
          })
        })
      }
    }
  })
})
