// TODO: Use Stimulus for this

function toggleCurrentPriceRequired() {
  const required = document.querySelectorAll('[data-toggle-current-price-required=true]:checked').length > 0
  const currentPriceEl = document.querySelector('[data-current-price]')
  const currentPriceFormField = currentPriceEl.closest('.FormField')

  if (required) {
    currentPriceEl.setAttribute('required', 'required')
    currentPriceFormField.classList.add('FormField--required')
  } else {
    currentPriceEl.removeAttribute('required')
    currentPriceFormField.classList.remove('FormField--required')
  }
}

function toggleCurrentPriceRequiredHandler() {
  if (document.querySelectorAll('[data-toggle-current-price-required]').length > 0) {
    toggleCurrentPriceRequired()
  }

  document.addEventListener('change', (e) => {
    if (e.target.matches('[data-toggle-current-price-required]')) {
      toggleCurrentPriceRequired()
    }
  })
}

document.addEventListener('turbo:load', toggleCurrentPriceRequiredHandler)
export default toggleCurrentPriceRequiredHandler
