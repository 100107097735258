// Calls hide for u-hidden that also have js-disable-when-hidden inputs since hide now also disables inputs

import { hide } from '../lib/helpers'

function hideHiddenElements() {
  document.querySelectorAll('.js-disable-when-hidden.u-hidden').forEach(hide)
}

document.addEventListener('starfish:render', hideHiddenElements)

export default hideHiddenElements
