import { Controller } from '@hotwired/stimulus'

export default class VisionInsuranceController extends Controller {
  static targets = [
    'insuranceAmount',
    'rightEyeQuantity',
    'leftEyeQuantity',
    'selectOptimalQuantities',
    'leftEyeBreakdownQuantityLabel',
    'leftEyeBreakdownPerUnitLabel',
    'leftEyeBreakdownTotalLabel',
    'rightEyeBreakdownQuantityLabel',
    'rightEyeBreakdownPerUnitLabel',
    'rightEyeBreakdownTotalLabel',
  ]

  selectOptimalQuantities() {
    if (this.hasLeftEyeQuantityTarget) {
      setInputValue(this.leftEyeQuantityTarget, this.optimized.leftEyeQuantity.toString())
    }
    if (this.hasRightEyeQuantityTarget) {
      setInputValue(this.rightEyeQuantityTarget, this.optimized.rightEyeQuantity.toString())
    }
  }

  insuranceAmountChanged() {
    this.recalculateQuantities()
  }

  quantityChanged() {
    this.recalculateQuantities()
    this.refreshBreakdown()
  }

  refreshBreakdown() {
    if (this.hasLeftEyeQuantityTarget) {
      const leftEyeQuantity = Number(this.leftEyeQuantityTarget.value)
      this.leftEyeBreakdownQuantityLabelTarget.textContent = this.leftEyeQuantityTarget.value
      this.leftEyeBreakdownTotalLabelTarget.textContent = (
        leftEyeQuantity * Number(this.leftEyeBreakdownPerUnitLabelTarget.textContent)
      ).toFixed(2)
    }

    if (this.hasRightEyeQuantityTarget) {
      const rightEyeQuantity = Number(this.rightEyeQuantityTarget.value)
      this.rightEyeBreakdownQuantityLabelTarget.textContent = this.rightEyeQuantityTarget.value
      this.rightEyeBreakdownTotalLabelTarget.textContent = (
        rightEyeQuantity * Number(this.rightEyeBreakdownPerUnitLabelTarget.textContent)
      ).toFixed(2)
    }
  }

  recalculateQuantities() {
    const insuranceAmount = Number(this.insuranceAmountTarget.value)
    const leftEyePricePerUnit = this.hasLeftEyeQuantityTarget
      ? Number(this.leftEyeBreakdownPerUnitLabelTarget.textContent)
      : 0
    const rightEyePricePerUnit = this.hasRightEyeQuantityTarget
      ? Number(this.rightEyeBreakdownPerUnitLabelTarget.textContent)
      : 0

    const leftEyeMaxQuantity = this.hasLeftEyeQuantityTarget ? maxOption(this.leftEyeQuantityTarget) : 0
    const rightEyeMaxQuantity = this.hasRightEyeQuantityTarget ? maxOption(this.rightEyeQuantityTarget) : 0

    this.optimized = this.calculateOptimalQuantities({
      insuranceAmount,
      leftEyePricePerUnit,
      leftEyeMaxQuantity,
      rightEyePricePerUnit,
      rightEyeMaxQuantity,
    })

    const existing = {
      leftEyeQuantity: this.hasLeftEyeQuantityTarget ? Number(this.leftEyeQuantityTarget.value) : 0,
      rightEyeQuantity: this.hasRightEyeQuantityTarget ? Number(this.rightEyeQuantityTarget.value) : 0,
    }

    const optimizable =
      this.optimized.leftEyeQuantity !== existing.leftEyeQuantity ||
      this.optimized.rightEyeQuantity !== existing.rightEyeQuantity

    this.selectOptimalQuantitiesTarget.disabled = !optimizable
  }

  calculateOptimalQuantities({
    insuranceAmount,
    leftEyePricePerUnit,
    rightEyePricePerUnit,
    leftEyeMaxQuantity,
    rightEyeMaxQuantity,
  }) {
    const prices = [leftEyePricePerUnit, rightEyePricePerUnit]
    const maximums = [leftEyeMaxQuantity, rightEyeMaxQuantity]
    const quantities = [0, 0]

    function totalPrice() {
      return prices[0] * quantities[0] + prices[1] * quantities[1]
    }

    function canAddTo(eye) {
      return quantities[eye] < maximums[eye] && totalPrice() + prices[eye] <= insuranceAmount
    }

    for (;;) {
      // if it's possible to add one more to the eye that has the lowest quantity, do it
      let eye = quantities.indexOf(Math.min(...quantities))
      if (canAddTo(eye)) {
        quantities[eye]++
      } else {
        // add as many as possible of to the other eye
        eye = 1 - eye
        while (canAddTo(eye)) {
          quantities[eye]++
        }
        break
      }
    }
    return { leftEyeQuantity: quantities[0], rightEyeQuantity: quantities[1] }
  }
}

function setInputValue(input, text) {
  input.value = text
  input.dispatchEvent(new CustomEvent('change'))
}

function maxOption(input) {
  return Number(input.max)
}
