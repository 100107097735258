import realMixpanel from 'mixpanel-browser'
import { camelCase } from 'lodash'
import { isPreviewRender } from '../helpers'

class Config {
  constructor() {
    this.dataset = document.body.dataset
  }

  token() {
    return this.dataset.mixpanelToken
  }

  ilg_token() {
    return this.dataset.mixpanelIlgToken
  }

  testMode() {
    return this.dataset?.mixpanelTesting === 'true' || false
  }
}

class FakeMixpanel {
  constructor(events) {
    this.events = events
    window.mixpanelIlgEvents = []
    this.ilg = {
      track: function (event, properties) {
        this.events = window.mixpanelIlgEvents
        this.events.push({
          type: 'track',
          event,
          properties,
        })
      },
    }
  }

  init(token) {
    this.events.push({
      type: 'init',
      token: token,
    })
  }

  track(event, properties) {
    this.events.push({
      type: 'track',
      event,
      properties,
    })
  }
}

class Tracker {
  constructor() {
    this.config = new Config()
    this.mixpanel = this.config.testMode() ? new FakeMixpanel((window.mixpanelEvents = [])) : realMixpanel
  }

  start() {
    if (this.config.token()) {
      this.mixpanel.init(this.config.token(), { debug: true })
    }
    if (this.config.ilg_token()) {
      this.mixpanel.init(this.config.ilg_token(), { debug: true }, 'ilg')
    }
    if (this.config.ilg_token() || this.config.token()) {
      this.trackPage()

      window.addEventListener('turbo:render', () => {
        if (!isPreviewRender()) {
          this.trackPage()
        }
      })
    }
  }

  trackPage() {
    const { event, properties } = this.mixpanelData()
    if (this.config.token()) {
      this.mixpanel.track(event, properties)
    }
    if (this.config.ilg_token()) {
      this.mixpanel.ilg.track(event, properties)
    }
  }

  mixpanelData() {
    const dataset = Object.assign({}, document.body.dataset)

    const mixpanelData = removeObjectKeyPrefix(dataset, 'mixpanel')
    const mixpanelProperties = removeObjectKeyPrefix(mixpanelData, 'properties')

    return {
      event: mixpanelData.event,
      properties: mixpanelProperties,
    }
  }
}

function removeObjectKeyPrefix(object, prefix) {
  return Object.fromEntries(
    Object.entries(object).flatMap(([key, value]) => {
      if (key.startsWith(prefix) && key.length > prefix.length) {
        return [[camelCase(key.substring(prefix.length)), value]]
      } else {
        return []
      }
    })
  )
}

export function startTracking() {
  new Tracker().start()
}
