// Load PhotoSwipe for image galleries

import PhotoSwipeLightbox from 'photoswipe/lightbox'

async function imageGallery() {
  const imageGalleries = document.querySelectorAll('.js-image-gallery')
  if (imageGalleries.length) {
    import('photoswipe/dist/photoswipe.css')
    imageGalleries.forEach((imageGallery) => {
      const lightbox = new PhotoSwipeLightbox({
        bgOpacity: 0.6,
        gallery: imageGallery,
        children: 'a',
        pswpModule: () => import('photoswipe'),
      })
      lightbox.init()
    })
  }
}

document.addEventListener('starfish:render', imageGallery)

export default imageGallery
