import { show, hide, brandColorPrimary, brandColorSecondary } from '../helpers'

async function loadGoogle(mapEl) {
  const { Loader } = await import('@googlemaps/js-api-loader')

  const loader = new Loader({
    apiKey: mapEl.dataset.googleMapsKey,
    version: 'weekly',
    libraries: ['places'],
  })

  return await loader.load()
}

class Map {
  static distanceMatrixChunkSize = 25
  constructor(google, mapEl) {
    this.google = google

    const mapOptions = {
      center: {
        lat: 0,
        lng: 0,
      },
    }

    this.googleMap = new this.google.maps.Map(mapEl, mapOptions)

    this.originMarker = new google.maps.Marker({ map: this.googleMap })
    this.setMarkerSelected(this.originMarker, true)
    this.originMarker.setVisible(false)
  }

  setOriginMarkerPosition(position) {
    this.originMarker.setPosition(position)
    this.originMarker.setVisible(true)
  }

  setMarkerSelected(marker, selected) {
    const svgMarker = {
      path: 'M 12,0.71 a 7.5,7.5 0 0 1 7.5,7.5 c 0,3.547 -5.5,12.381 -7.079,14.85 a 0.5,0.5 0 0 1 -0.842,0 C 10,20.592 4.5,11.757 4.5,8.21 A 7.5,7.5 0 0 1 12,0.71 Z',
      fillColor: selected ? brandColorPrimary() : brandColorSecondary(),
      fillOpacity: selected ? 1 : 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new this.google.maps.Point(15, 30),
    }

    marker.setIcon(svgMarker)
  }

  createLocationForMarker(location) {
    const marker = new this.google.maps.Marker({
      position: location.position,
      map: this.googleMap,
    })

    this.setMarkerSelected(marker, false)
    marker.setTitle(location.title)

    this.google.maps.event.addListener(marker, 'click', () => {
      location.setSelected(true)
      location.mapDataEl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    })

    return marker
  }

  fitBoundsToPositions(positions) {
    const latlngBounds = new this.google.maps.LatLngBounds()

    positions.forEach((position) => {
      latlngBounds.extend(position)
    })

    this.googleMap.panTo(latlngBounds.getCenter())
    this.googleMap.fitBounds(latlngBounds)
  }

  zoomToPosition(position) {
    this.googleMap.panTo(position)
    this.googleMap.setZoom(9)
  }
}

class Autocomplete {
  static searchRadiusTarget = '[data-locations-search-radius]'

  constructor({ google, locations, map, countryCode, unitSystem }) {
    this.google = google
    this.unitSystem = unitSystem
    this.locations = locations
    this.map = map

    const searchOptions = {
      componentRestrictions: { country: countryCode },
    }

    this.autocomplete = new this.google.maps.places.Autocomplete(
      document.querySelector('.js-locations-search-input'),
      searchOptions
    )

    this.resultsHeading = document.querySelector('.js-results-heading')
    this.noLocationResults = document.querySelector('.js-no-locations-results')
    this.noPlaceMatches = document.querySelector('.js-no-place-matches')
    const searchRadiusSelect = document.querySelector(Autocomplete.searchRadiusTarget)

    searchRadiusSelect.addEventListener('change', this.onSearch)
    this.autocomplete.addListener('place_changed', this.onSearch)
  }

  onSearch = async () => {
    const place = this.autocomplete.getPlace()
    if (!place) {
      return
    }

    this.resetResults()

    if (!place.geometry) {
      show(this.noPlaceMatches)
      return
    }

    const originPosition = place.geometry.location

    this.map.setOriginMarkerPosition(originPosition)

    const locationsInOrder = await this.calculateAndSortByDistance(originPosition)
    this.renderResults(originPosition, locationsInOrder)
  }

  resetResults() {
    hide(this.noLocationResults)
    hide(this.noPlaceMatches)
    show(this.resultsHeading)
    this.locations.clearSelectedLocations()
    this.map.originMarker.setVisible(false)
  }

  renderResults(originPosition, locationDistances) {
    const maxDistance = this.getMaxDistance()
    const locationsInSearchRadius = locationDistances.reduce((acc, { location, distanceVal }) => {
      if (distanceVal <= maxDistance) {
        acc.push(location)
      }
      return acc
    }, [])

    if (locationsInSearchRadius.length === 0) {
      show(this.noLocationResults)
      return
    }

    locationDistances.forEach(({ location, distanceText }) => {
      location.setDistanceText(distanceText)
    })

    const sortedLocations = locationDistances.map(({ location }) => location)

    this.locations.reorderLocations(sortedLocations)
    this.map.fitBoundsToPositions([originPosition, ...locationsInSearchRadius.map((l) => l.position)])
  }

  getDistanceMatrix(parameters) {
    const service = new this.google.maps.DistanceMatrixService()

    return new Promise((resolve, reject) => {
      service.getDistanceMatrix(parameters, (response, status) => {
        if (status === this.google.maps.DistanceMatrixStatus.OK) {
          resolve(response)
        } else {
          reject(response)
        }
      })
    })
  }

  chunk(items, size) {
    const chunked = []
    for (let i = 0; i < items.length; i += size) {
      chunked.push(items.slice(i, i + size))
    }
    return chunked
  }

  async getDistanceMatrixChunked(destinations, origin, chunkSize) {
    const chunkedDestinations = this.chunk(destinations, chunkSize)

    const promises = chunkedDestinations.map((chunk) =>
      this.getDistanceMatrix({
        origins: [origin],
        destinations: chunk,
        travelMode: 'DRIVING',
        unitSystem: this.google.maps.UnitSystem[this.unitSystem],
      })
    )
    const results = await Promise.all(promises)
    return results.flatMap((result) => result.rows[0].elements)
  }

  getMaxDistance() {
    return parseInt(document.querySelector(Autocomplete.searchRadiusTarget).value, 10)
  }

  async calculateAndSortByDistance(origin) {
    const destinations = this.locations.locations.map(({ position }) => position)
    const response = await this.getDistanceMatrixChunked(destinations, origin, Map.distanceMatrixChunkSize)

    const distances = response
      .map((element, index) => {
        if (element.status === 'OK') {
          return {
            location: this.locations.locations[index],
            distanceText: element.distance.text,
            distanceVal: element.distance.value,
          }
        }
      })
      .filter(Boolean)

    distances.sort((first, second) => {
      return first.distanceVal - second.distanceVal
    })

    return distances
  }
}

class Location {
  constructor({ map, locations, title, id, position, mapDataEl, showMapMinWidth }) {
    this.map = map
    this.locations = locations
    this.title = title
    this.id = id
    this.position = position
    this.mapDataEl = mapDataEl
    this.marker = this.map.createLocationForMarker(this)

    this.mapDataEl.querySelector('a').addEventListener('click', (e) => {
      const mediaQuery = window.matchMedia(showMapMinWidth)

      if (mediaQuery.matches) {
        e.preventDefault()
        this.map.zoomToPosition(this.marker.getPosition())
        this.setSelected(true)
      }
    })
  }

  setDistanceText(distanceText) {
    const distanceEl = this.mapDataEl.querySelector('.js-distance')
    show(distanceEl)
    const distanceTextEl = distanceEl.querySelector('.js-distance-value')
    distanceTextEl.textContent = `${distanceText} `
  }

  setSelected(selected) {
    if (selected) {
      this.locations.clearSelectedLocations()

      this.mapDataEl.classList.add('u-backgroundLight')
    } else {
      this.mapDataEl.classList.remove('u-backgroundLight')
    }

    this.map.setMarkerSelected(this.marker, selected)
  }
}

class Locations {
  constructor(map, { showMapMinWidth }) {
    this.locations = []

    document.querySelectorAll('.js-locations-map-data').forEach((mapDataEl) => {
      this.locations.push(
        new Location({
          map,
          locations: this,
          title: mapDataEl.dataset.locationTitle,
          id: mapDataEl.dataset.locationId,
          position: {
            lat: Number(mapDataEl.dataset.locationLat),
            lng: Number(mapDataEl.dataset.locationLng),
          },
          mapDataEl,
          showMapMinWidth,
        })
      )
    })
  }

  clearSelectedLocations() {
    this.locations.forEach((location) => {
      location.setSelected(false)
    })
  }

  reorderLocations(locationsInOrder) {
    const stores = document.querySelector('.js-location-results')
    while (stores.lastChild) {
      stores.removeChild(stores.lastChild)
    }

    locationsInOrder.forEach((location) => {
      stores.appendChild(location.mapDataEl)
    })
  }
}

export class LocationMap {
  constructor({ google, mapEl, showMapMinWidth }) {
    const map = new Map(google, mapEl)
    const locations = new Locations(map, { showMapMinWidth })

    new Autocomplete({
      google,
      locations,
      map,
      countryCode: mapEl.dataset.countryCode,
      unitSystem: mapEl.dataset.unitSystem,
    })

    map.fitBoundsToPositions(locations.locations.map((l) => l.position))
  }
}

export async function initializeMap({ showMapMinWidth = '(min-width: 1024px)' } = {}) {
  const mapEl = document.querySelector('[data-js-locations-map]')

  if (mapEl) {
    const google = await loadGoogle(mapEl)

    new LocationMap({
      google,
      mapEl,
      showMapMinWidth,
    })
  }
}
