// Search address via Loqate and populate shipping address fields

import LoqateApi from '../lib/loqate_api'

function initializeAutocomplete(addressSearchField, accessibleAutocomplete) {
  const key = addressSearchField.dataset.loqateAddressApiKey
  const url = addressSearchField.dataset.loqateAddressApiUrl
  const fieldPrefix = addressSearchField.dataset.loqateAddressFieldPrefix
  const countries = addressSearchField.dataset.loqateAddressCountries
  const placeholder = addressSearchField.dataset.placeholder
  const noResults = addressSearchField.dataset.noResults

  if (addressSearchField.firstChild) {
    addressSearchField.removeChild(addressSearchField.firstChild)
  }

  const loqateApi = new LoqateApi({ key, url, countries: countries })

  accessibleAutocomplete({
    element: addressSearchField,
    id: 'address_search',
    cssNamespace: 'Autocomplete',
    displayMenu: 'overlay',
    placeholder: placeholder,
    source: (query, populateResults) => loqateApi.suggest(query, populateResults),
    onConfirm: async (address) => {
      const fullAddress = await loqateApi.getAddress(address)
      selectAddress(fullAddress)
    },
    templates: {
      inputValue: getDisplayText,
      suggestion: getDisplayText,
    },
    tNoResults: () => noResults,
    tStatusNoResults: () => noResults,
  })

  function getDisplayText(input) {
    return input ? `${input.Text} ${input.Description}` : ''
  }

  async function selectAddress(fullAddress) {
    if (fullAddress) {
      Object.keys(fullAddress).forEach((key) => {
        const name = `${fieldPrefix}[${key}]`
        const field = document.querySelector(`input[type=text][name="${name}"], select[name="${name}"]`)

        if (field) {
          field.value = fullAddress[key]
          field.dispatchEvent(new CustomEvent('change'))
        }
      })
    }
  }
}

document.addEventListener('turbo:load', async () => {
  const addressSearchField = document.querySelector('[data-loqate-address-api-url]')
  if (addressSearchField) {
    const { default: accessibleAutocomplete } = await import('accessible-autocomplete')
    initializeAutocomplete(addressSearchField, accessibleAutocomplete)
  }
})

document.addEventListener('turbo:frame-render', async () => {
  const addressSearchField = document.querySelector('[data-loqate-address-api-url]')
  if (addressSearchField) {
    const { default: accessibleAutocomplete } = await import('accessible-autocomplete')
    initializeAutocomplete(addressSearchField, accessibleAutocomplete)
  }
})
