// Generic utility for opening cookie settings modal

function configureCookies(cookieConfigureElem, cookiesModal) {
  cookieConfigureElem.addEventListener('click', (e) => {
    e.preventDefault()
    cookiesModal.showModal()
  })
}

document.addEventListener('turbo:load', () => {
  const cookieConfigureElem = document.querySelector('.js-cookies-configure')
  const cookiesModal = document.querySelector('.js-modal-configure-cookies')
  if (cookieConfigureElem && cookiesModal) {
    configureCookies(cookieConfigureElem, cookiesModal)
  }
})
