import { Controller } from '@hotwired/stimulus'

export default class ColorFieldController extends Controller {
  static targets = ['input', 'label']

  updateLabel() {
    this.labelTarget.style.backgroundColor = this.inputTarget.value
    this.labelTarget.textContent = this.inputTarget.value
  }
}
