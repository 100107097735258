// Add class to floating inputs if/when they have a value
import { toggleValueClass } from '../lib/helpers'

function makeLabelsFloat(e) {
  e.target
    .querySelectorAll('.FormField--floating .FormField-input, .FormField--floating .FormField-select')
    .forEach((element) => {
      toggleValueClass(element)
      ;['keyup', 'change'].forEach((event) => {
        element.addEventListener(event, () => toggleValueClass(element))
      })
    })
}

document.addEventListener('turbo:load', makeLabelsFloat)
document.addEventListener('turbo:frame-render', makeLabelsFloat)
document.addEventListener('turbo:render', makeLabelsFloat)
