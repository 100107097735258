import Rails from '@rails/ujs'

function handleFormSubmit(e, modals, interceptButtons) {
  e.preventDefault()

  // Prevent Rails from disabling all the submit buttons
  Rails.stopEverything(e)

  // Function to show modals sequentially
  function showNextModal(index) {
    if (index >= modals.length) {
      // All modals have been shown, submit the form
      e.target.submit()
      interceptButtons.forEach((button) => Rails.disableElement(button))
      return
    }

    const currentModal = modals[index]
    const continueButton = currentModal.querySelector('.js-intercept-form-continue')
    const closeButtons = currentModal.querySelectorAll('.js-modal-close')

    // Show the current modal
    currentModal.showModal()

    // Set up the continue button to close the current modal and show the next one
    continueButton.addEventListener(
      'click',
      () => {
        currentModal.close()
        showNextModal(index + 1)
      },
      { once: true }
    )

    // Set up the close buttons to close the modal
    closeButtons.forEach((button) => {
      button.addEventListener(
        'click',
        () => {
          currentModal.close()
        },
        { once: true }
      )
    })
  }

  // Start showing modals from the first one
  showNextModal(0)
}

function initializeInterceptForm() {
  const modals = document.querySelectorAll('.js-modal-intercept-form')
  const interceptButtons = document.querySelectorAll('.js-intercept-form-submit')

  if (modals.length && interceptButtons.length) {
    interceptButtons.forEach((button) => {
      // Intercept form submit after browser validation
      button.form.addEventListener('submit', (e) => {
        handleFormSubmit(e, modals, interceptButtons)
      })
    })
  }
}

document.addEventListener('turbo:load', initializeInterceptForm)
document.addEventListener('turbo:render', initializeInterceptForm)
document.addEventListener('turbo:frame-render', initializeInterceptForm)
