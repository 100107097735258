import { Controller } from '@hotwired/stimulus'

export default class FocalPointController extends Controller {
  static targets = ['percentX', 'percentY']

  setFocalPoint(e) {
    const rect = e.target.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    const w = e.target.width
    const h = e.target.height
    const percentX = (x / w) * 100
    const percentY = (y / h) * 100
    this.percentXTarget.value = percentX.toFixed(0)
    this.percentYTarget.value = percentY.toFixed(0)

    // Ensure we trigger a "change" event for any other listeners (i.e. Stimulus controllers) to detect.
    // Only one of the targets requires this as the end result is the same.
    this.percentXTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }
}
