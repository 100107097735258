/*global FitMix*/

import { Controller } from '@hotwired/stimulus'

import { hide, show } from '../../lib/helpers'

export default class VirtualTryOnController extends Controller {
  static targets = ['container', 'fitmixRoot', 'startControl', 'gallery', 'hiddenForNarrowViewports', 'errorMessage']
  static values = { apiKey: String, product: String, lang: String }

  connect() {
    this.attachFittingBoxScript()
    window.addEventListener('message', this.iframeListener.bind(this))
  }

  iframeListener(event) {
    if (event.origin === 'https://vto-advanced.fittingbox.com') {
      switch (event.data.method) {
        case 'connect': {
          this.handleConnectEvent(event.data.params)
        }
      }
    }
  }

  handleConnectEvent({ liveSupported }) {
    if (liveSupported === false) {
      this.showErrorScreen()
    }
  }

  showErrorScreen() {
    this.productMode()
    show(this.errorMessageTarget)
  }

  attachFittingBoxScript() {
    if (!document.getElementById('fittingbox-snippet')) {
      const script = document.createElement('script')
      script.id = 'fittingbox-snippet'
      script.src = 'https://vto-advanced-integration-api.fittingbox.com/index.js'
      document.head.appendChild(script)
      script.addEventListener('load', () => this.activateFittingBox())
    } else {
      this.activateFittingBox()
    }
  }

  activateFittingBox() {
    const params = {
      apiKey: this.apiKeyValue,
      lang: this.langValue,
      onStopVto: this.productMode.bind(this),
    }

    this.fitmixInstance = FitMix.createWidget(this.fitmixRootTarget.id, params, () =>
      this.fitmixInstance.setFrame(this.productValue)
    )
  }

  productMode() {
    hide(this.containerTarget)
    show(this.galleryTarget)
    show(this.startControlTarget)

    this.hiddenForNarrowViewportsTarget.dataset.vtoActive = 'false'
  }

  vtoMode() {
    hide(this.errorMessageTarget)
    hide(this.startControlTarget)
    hide(this.galleryTarget)
    show(this.containerTarget)

    this.hiddenForNarrowViewportsTarget.dataset.vtoActive = 'true'
  }

  start() {
    this.fitmixInstance.startVto('live')
    this.vtoMode()
  }

  disconnect() {
    this.close()
    this.productMode()
  }

  close() {
    window.removeEventListener('message', this.iframeListener.bind(this))
    if (this.fitmixInstance) {
      this.fitmixInstance.stopVto()
    }
  }
}
