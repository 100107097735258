// Smooth scroll to anchor link on page load if in the URL

async function scrollToAnchor() {
  if (window.location.hash) {
    const target = document.querySelector(window.location.hash)

    if (target) {
      const { default: scrollIntoView } = await import('scroll-into-view-if-needed')

      scrollIntoView(target, { block: 'start', behavior: 'smooth' })
    }
  }
}

document.addEventListener('turbo:load', scrollToAnchor)

export default scrollToAnchor
