import { Controller } from '@hotwired/stimulus'
import { hide, show } from '../../lib/helpers'

export default class PatientPickerController extends Controller {
  static targets = ['radio', 'container']

  connect() {
    this.showOrHide()
  }

  showOrHide() {
    if (this.selectedRadioValue() === 'new_record') {
      show(this.containerTarget)
    } else {
      hide(this.containerTarget)
    }
  }

  selectedRadioValue() {
    const selected = Array.from(this.radioTargets).find((radio) => radio.checked)

    return selected?.value
  }
}
