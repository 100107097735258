// Populates "Expiration date" field when "Start date" and "Term" are selected
import { addMonths, isoDateFormat, isValidDate } from '../lib/helpers'

function prescriptionExpiresOnForTerm(container) {
  const finalityInput = container.querySelector('#line_item_group_detail_finality')
  const startDateInput = container.querySelector('.js-prescription-expires-start-date-input')
  const termInput = container.querySelector('.js-prescription-expires-term-input') || startDateInput
  const expiresOnInput = container.querySelector('.js-prescription-expires-expires-on-input')

  const isTrialPrescription = finalityInput !== null && finalityInput.checked

  if (!isTrialPrescription && startDateInput && termInput && expiresOnInput) {
    initializeExpiryDateCalculationForTerm(startDateInput, termInput, expiresOnInput)
  }
}

function initializeExpiryDateCalculationForTerm(startDateInput, termInput, expiresOnInput) {
  startDateInput.addEventListener('change', calculateExpiryDateForTerm)
  // Add event listener if termInput is a separate element
  if (termInput !== startDateInput) termInput.addEventListener('change', calculateExpiryDateForTerm)

  function calculateExpiryDateForTerm() {
    // Determine the term using the value of termInput if it exists,
    // otherwise use the data-term attribute from startDateInput
    const term = termInput.classList.contains('js-prescription-expires-term-input')
      ? termInput.value
      : startDateInput.dataset.term

    if (startDateInput.value && term) {
      const startDate = new Date(Date.parse(startDateInput.value))
      if (isValidDate(startDate)) {
        expiresOnInput.value = isoDateFormat(addMonths(startDate, Number(term)))
        expiresOnInput.dispatchEvent(new CustomEvent('change'))
      }
    }
  }
}

function initializePrescriptionExpiresOn() {
  const prescriptionExpiresOnForTermContainer = document.querySelector('.js-prescription-expires-on-for-term')
  if (prescriptionExpiresOnForTermContainer) {
    prescriptionExpiresOnForTerm(prescriptionExpiresOnForTermContainer)
  }
}

document.addEventListener('turbo:load', initializePrescriptionExpiresOn)
document.addEventListener('turbo:render', initializePrescriptionExpiresOn)
document.addEventListener('turbo:frame-render', initializePrescriptionExpiresOn)

export { prescriptionExpiresOnForTerm }
