async function refreshServerSession(path, reload, fetch) {
  const csrfTag = document.querySelector("[name='csrf-token']")
  const response = await fetch(path, { method: 'POST', headers: { 'X-CSRF-Token': csrfTag.content } })

  if (response.status === 401) {
    reload()
  }

  const data = await response.json()

  csrfTag.setAttribute('content', data.csrfToken)

  return data.sessionTimeout
}

// Session timeout overlay with countdown
function sessionTimeout({
  modal,
  reload = () => location.reload(),
  fetch = window.fetch,
  timeoutInterval = 1000,
} = {}) {
  clearTimeout(modalShowTimeout)

  let freshModal = modal || document.querySelector('.js-modal-session-timeout')
  if (!freshModal) {
    return
  }

  const timeoutSeconds = freshModal.dataset.sessionTimeout
  const refreshServerPath = freshModal.dataset.extendSessionPath

  modalShowTimeout = setTimeout(() => {
    freshModal = modal || document.querySelector('.js-modal-session-timeout')
    const secondsLeftText = document.querySelector('.js-timeout-remaining')

    secondsLeftText.innerHTML = 30
    let secondsLeft = 30

    async function modalOnClose() {
      clearInterval(countDownInterval)
      await refreshServerSession(refreshServerPath, reload, fetch)
      sessionTimeout()
    }

    // Count down last 30 seconds
    const countDownInterval = setInterval(() => {
      if (secondsLeft >= 0) {
        secondsLeftText.innerHTML = secondsLeft
      } else {
        clearInterval(countDownInterval)
        reload()
      }
      secondsLeft -= 1
    }, timeoutInterval)

    // Extend session if modal closed
    freshModal.addEventListener('close', modalOnClose, { once: true })

    freshModal.showModal()
    // Timeout in milliseconds minus 30 second warning
  }, timeoutSeconds * 1000 - 30000)
}

let modalShowTimeout

document.addEventListener('turbo:load', sessionTimeout)
document.addEventListener('turbo:before-fetch-response', sessionTimeout)
// TODO: regular `fetch` requests also extend server session, yet they are not covered by this code

export default sessionTimeout
