const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
import { mapKeys } from 'lodash'
import { show } from '../lib/helpers'

function mapKeysToSnakeCase(dataset) {
  return mapKeys(dataset, (_value, key) => camelToSnakeCase(key))
}

function subtreeFromMenuEditor(element) {
  return nextDescendantsWithIds(element).map((e) => {
    const attributes = mapKeysToSnakeCase(e.dataset)
    attributes.children = subtreeFromMenuEditor(e)

    return attributes
  })
}

function nextDescendantsWithIds(element) {
  const withIds = []
  for (const child of element.children) {
    if (child.dataset.type) {
      withIds.push(child)
    } else {
      withIds.push(...nextDescendantsWithIds(child))
    }
  }
  return withIds
}

async function menuEditor(documentElement) {
  const menuEditorElement = documentElement.querySelector('.js-menu-editor')
  if (!menuEditorElement) {
    return
  }

  const { default: dragula } = await import('dragula')

  attachDropAreas()
  const inMenuSubtreeElement = documentElement.querySelector('.js-menu-editor-in-menu-subtree')

  documentElement.querySelectorAll('.js-modal-menu-item-editor').forEach((modal) => {
    modal.showModal()
    modal.addEventListener('close', () => {
      modal.remove()
    })
  })

  const form = documentElement.querySelector('.js-save-menu-form')
  show(form)

  function serializeMenuSubTree() {
    const input = form.querySelector("input[name='cms_menu[subtree_as_json]']")
    const subtree = subtreeFromMenuEditor(inMenuSubtreeElement)
    input.value = JSON.stringify(subtree)
  }

  form.addEventListener('submit', () => serializeMenuSubTree())

  addAddHandlers(documentElement)

  function descendantDepthOf(element) {
    const depth = element.dataset.cmsPageId ? 1 : 0
    return Math.max(depth, ...Array.from(element.children).map((child) => depth + descendantDepthOf(child)))
  }

  function countAncestorsOf(element) {
    return element.parentElement ? (element.dataset.cmsPageId ? 1 : 0) + countAncestorsOf(element.parentElement) : 0
  }

  function attachDropAreas() {
    if (menuEditorElement.drake) {
      menuEditorElement.drake.destroy()
      menuEditorElement.drake = null
    }
    const nestableNesteds = Array.from(documentElement.querySelectorAll('.js-nestable-drop-area'))

    let draggingDepth = 0
    const maxDepth = Number(menuEditorElement.dataset.maxDepth)

    menuEditorElement.drake = dragula(nestableNesteds, {
      accepts: (el, target /*, source, sibling */) => {
        const targetDepth = countAncestorsOf(target)
        const totalDepth = targetDepth + draggingDepth

        if (target.matches('.js-menu-editor-in-menu-subtree *') && totalDepth <= maxDepth) {
          return true
        }
        return false
      },
    })
      .on('drag', (el) => {
        draggingDepth = descendantDepthOf(el)
      })
      .on('dragend', () => {
        form.requestSubmit()
      })
  }

  // Add item to menu on click
  function addAddHandlers(element) {
    element.querySelectorAll('.js-nestable-add').forEach((button) => {
      button.addEventListener('click', (e) => {
        const item = e.target.closest('.js-nestable-item')
        documentElement.querySelector('.js-menu-editor-in-menu-subtree > .js-nestable-drop-area').appendChild(item)
      })
    })
  }
}

document.addEventListener('turbo:load', ({ target }) => menuEditor(target))
document.addEventListener('turbo:frame-load', ({ target }) => menuEditor(target))
document.addEventListener('turbo:render', ({ target }) => menuEditor(target))
