import { hideIfExists, show, hide } from '../lib/helpers'
import { fetchJson } from './fetch_json'

async function getServerSideConfirmation(confirmationUrl) {
  const { found, error, duplicate, paymentMethodId } = await fetchJson(confirmationUrl)

  if (error) {
    throw new Error(error)
  }

  if (found) {
    return { duplicate, paymentMethodId }
  }
}

async function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export async function waitForServerSideConfirmation(confirmationUrl) {
  for (;;) {
    const found = await getServerSideConfirmation(confirmationUrl)
    if (found) {
      return found
    }
    await sleep(1000)
  }
}

export class ResultDiv {
  show(result) {
    if (this.lastResultDiv) {
      hide('.js-iframe-add-card-' + this.lastResultDiv)
    }
    show('.js-iframe-add-card-' + result)

    this.lastResultDiv = result
    hideIfExists('.js-slp-card-fields')
  }
}
