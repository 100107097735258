function initializeSignaturePad(canvas, SignaturePad) {
  const clearButton = document.querySelector('.js-signature-clear')
  const hiddenInput = document.querySelector('.js-signature-hidden-input')
  const signaturePad = new SignaturePad(canvas)

  // Save canvas as encoded PNG to hidden input when stroke ends
  if (hiddenInput) {
    signaturePad.addEventListener('endStroke', () => {
      hiddenInput.value = signaturePad.toDataURL()
    })
  }

  // Clear canvas and hidden input when "Clear" clicked
  clearButton.addEventListener('click', () => clearSignature())

  function clearSignature() {
    signaturePad.clear()
    if (hiddenInput) {
      hiddenInput.value = ''
    }
  }

  // Adjust canvas coordinate space taking into account pixel ratio,
  // to make it look crisp on mobile devices.
  // NOTE: This causes canvas to be cleared when browser is resized.
  function resizeCanvas() {
    // When zoomed out to less than 100% some browsers report devicePixelRatio
    // as less than 1 and only part of the canvas is cleared then.
    const ratio = Math.max(window.devicePixelRatio || 1, 1)

    // Cause the canvas to be cleared
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)

    // signature_pad does not listen for canvas changes, so after the canvas is automatically
    // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
    // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
    // that the state of this library is consistent with visual state of the canvas, clear it manually.
    clearSignature()
  }

  window.onresize = resizeCanvas
  resizeCanvas()
}

async function signature() {
  const canvas = document.querySelector('.js-signature-canvas')
  if (canvas) {
    const { default: SignaturePad } = await import('signature_pad')
    initializeSignaturePad(canvas, SignaturePad)
  }
}

document.addEventListener('turbo:load', signature)
document.addEventListener('turbo:render', signature)
