import { hide, loadScript, show } from '../lib/helpers'
import { waitForServerSideConfirmation, ResultDiv } from '../lib/iframe_card_fields'

const iframeContainerId = 'worldpay-iframe-container'

let worldpayScriptLoaded = false

async function loadWorldpayScript() {
  if (!worldpayScriptLoaded) {
    await loadScript(
      'https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js'
    )
    worldpayScriptLoaded = true
  }
}

window.addEventListener('turbo:load', async function () {
  const iframeContainer = document.querySelector('#' + iframeContainerId)
  const appLanguage = document.querySelector('body').getAttribute('data-language')

  if (iframeContainer) {
    await loadWorldpayScript()

    var customOptions = {
      iframeHelperURL: location.origin + '/worldpay/helper.html',
      url: iframeContainer.dataset.worldpayIframeUrl,
      type: 'iframe',
      inject: 'immediate',
      target: iframeContainerId,
      accessibility: true,
      // debug: true,
      language: appLanguage,
      country: 'gb',
      preferredPaymentMethod: 'VISA-SSL',
      resultCallback: async function ({ order }) {
        paymentForm.destroy()

        const resultDiv = new ResultDiv()

        // TODO: What is the order here? This seems to be false after successful partial payment
        if (order.status === 'success' || order.status === 'already_processed') {
          resultDiv.show('progress')

          const orderCode = order.orderKey.replace(/.*\^/, '')
          try {
            const confirmationUrl = `${location.origin}/worldpay/notifications/${orderCode}`
            const { duplicate } = await waitForServerSideConfirmation(confirmationUrl)

            const hiddenField = document.querySelector('#card_order_code')
            hiddenField.value = orderCode

            if (!duplicate) {
              hiddenField.form.submit()
            } else {
              resultDiv.show('duplicate')
            }
          } catch (error) {
            resultDiv.show('error')
          }
        } else {
          resultDiv.show('failure')
        }
      },
    }
    // Initialise the library and pass options
    var paymentForm = new window.WPCL.Library()
    paymentForm.setup(customOptions)

    // There is no official way to run code on wp iframe load
    const iframe = document.querySelector(`#${iframeContainerId} iframe`)
    iframe.addEventListener('load', function () {
      hide('#js-card-iframe-loading')
    })
  }
})

// Restore original html before navigating away from card page
window.addEventListener('turbo:before-cache', function () {
  const iframeContainer = document.querySelector('#' + iframeContainerId)

  if (iframeContainer) {
    show('#js-card-iframe-loading')
    iframeContainer.innerHTML = ''
  }
})
