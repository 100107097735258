import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'

export default class CmsPageEditController extends Controller {
  static values = {
    message: String,
  }

  initialize() {
    this.save = this.save.bind(this)
  }

  connect() {
    this.save = debounce(this.save, 1500)
  }

  handleClose(e) {
    e.preventDefault()
    const frame = this.element.closest('turbo-frame')
    const form = frame.querySelector('form')

    if (form) {
      const hiddenInput = document.createElement('input')
      hiddenInput.setAttribute('type', 'hidden')
      hiddenInput.setAttribute('name', 'redirect')
      hiddenInput.setAttribute('value', 'true')

      form.appendChild(hiddenInput)
      form.requestSubmit()
      hiddenInput.remove()
    }
  }

  save() {
    this.saved()
    this.element.requestSubmit()
  }

  editedTarget() {
    return document.getElementById('draft_edited')
  }

  unsaved() {
    this.editedTarget().value = '1'
  }

  saved() {
    this.editedTarget().value = '0'
  }

  confirmCancel(event) {
    if (this.editedTarget().value === '1') {
      event.returnValue = this.messageValue

      if (!window.confirm(this.messageValue)) event.preventDefault()
    }
  }
}
