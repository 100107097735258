// Switches between hidden images when a thumbnail is hovered over

async function hoverGallery() {
  const hoverGalleries = document.querySelectorAll('.js-hover-gallery')

  if (hoverGalleries.length) {
    hoverGalleries.forEach((hoverGallery) => {
      const thumbsList = hoverGallery.querySelector('.js-hover-gallery-thumbs')
      const mainList = hoverGallery.querySelector('.js-hover-gallery-items')

      if (thumbsList) {
        const thumbsItems = thumbsList.querySelectorAll('li')
        const mainItems = mainList.querySelectorAll('li')

        thumbsItems.forEach((thumb, index) => {
          // Disable default link behavior, we still want them clickable when no JS
          thumb.addEventListener('click', (e) => e.preventDefault())
          thumb.querySelector('a').style.cursor = 'default'

          thumb.addEventListener('mouseenter', () => {
            // Add active class to thumb and corresponding main item
            thumb.classList.add('is-active')
            mainItems[index].classList.add('is-active')

            // And remove it from all other items
            thumbsItems.forEach((_thumb) => {
              if (thumb !== _thumb) {
                _thumb.classList.remove('is-active')
              }
            })
            mainItems.forEach((item) => {
              if (item !== mainItems[index]) {
                item.classList.remove('is-active')
              }
            })
          })
        })
      }
    })
  }
}

document.addEventListener('starfish:render', hoverGallery)

export default hoverGallery
