// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within /app/packs and only use these pack files to reference
// that code so it'll be compiled.

// Browser polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Replace .no-js class with .js
document.documentElement.className = document.documentElement.className.replace('no-js', 'js')

import Rails from '@rails/ujs'
Rails.start()

import '@hotwired/turbo-rails'

import './controllers'

import LocalTime from 'local-time'
LocalTime.start()

// https://github.com/basecamp/local_time/issues/165
document.addEventListener('turbo:morph', () => {
  LocalTime.run()
})

require('./application.css')
require.context('../images', true)

// Import all scripts
const importAll = (r) => r.keys().forEach(r)
importAll(require.context('../scripts', false, /\.js$/))
