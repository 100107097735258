// Load Swiper for "PromoBarCarousel" UI component

async function promoBarCarousel() {
  const PromoBarCarousels = document.querySelectorAll('.js-promobar-carousel')

  if (PromoBarCarousels.length) {
    const { default: Swiper, Navigation, A11y, Autoplay, EffectFade } = await import('swiper')
    await Promise.all([
      import('swiper/css'),
      import('swiper/css/navigation'),
      import('swiper/css/a11y'),
      import('swiper/css/autoplay'),
      import('swiper/css/effect-fade'),
    ])

    PromoBarCarousels.forEach((PromoBarCarousel) => {
      new Swiper(PromoBarCarousel, {
        loop: true,
        slidesPerView: 1,
        modules: [Navigation, A11y, Autoplay, EffectFade],
        effect: 'fade',
        centeredSlides: true,
        autoplay: {
          delay: 3000,
        },
        navigation: {
          nextEl: PromoBarCarousel.parentElement.querySelector('.swiper-button-next'),
          prevEl: PromoBarCarousel.parentElement.querySelector('.swiper-button-prev'),
        },
      })
    })
  }
}

document.addEventListener('starfish:render', promoBarCarousel)

export default promoBarCarousel
