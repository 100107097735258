// Generic script to expand/collapse a target element with a toggle button
// TODO: Use Stimulus for this

function toggleNav() {
  const toggleButtons = document.querySelectorAll('.js-nav-toggle')

  if (toggleButtons.length) {
    toggleButtons.forEach((toggleButton) => {
      const targetNav = document.getElementById(toggleButton.getAttribute('aria-controls'))
      const labelElement = toggleButton.querySelector('.js-nav-toggle-label')
      const expandLabel = toggleButton.getAttribute('data-expand-label')
      const collapseLabel = toggleButton.getAttribute('data-collapse-label')

      if (targetNav) {
        toggleButton.addEventListener('click', () => {
          if (targetNav.classList.contains('is-active')) {
            targetNav.classList.remove('is-active')
            if (labelElement && expandLabel && collapseLabel) {
              labelElement.innerText = expandLabel
            }
          } else {
            targetNav.classList.add('is-active')
            if (labelElement && expandLabel && collapseLabel) {
              labelElement.innerText = collapseLabel
            }
          }
        })
      }
    })
  }
}

document.addEventListener('turbo:load', toggleNav)

export default toggleNav
