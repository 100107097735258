// Generic tabs behavior utility
// TODO: Use Stimulus for this

import { hide, show } from '../lib/helpers'

function tabsSwitcher(tabs, containers) {
  tabs.forEach((tab) => {
    tab.addEventListener('click', (e) => {
      e.preventDefault()
      const tabTarget = tab.getAttribute('href').substr(1)
      tabs.forEach((tab) => tab.classList.remove('is-active'))
      tab.classList.add('is-active')
      containers.forEach((container) => hide(container))
      show(document.getElementById(tabTarget))
    })
  })
}

document.addEventListener('turbo:load', () => {
  const tabs = document.querySelectorAll('.js-tabs-item')
  const containers = document.querySelectorAll('.js-tabs-container')
  if (tabs && containers) {
    tabsSwitcher(tabs, containers)
  }
})
