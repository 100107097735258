import storageAvailable from 'storage-available'

// Persist the chosen Store and terminal in local storage for return visits
function rememberSelection(selectInput) {
  const key = 'remember-selection:' + selectInput.dataset.rememberSelectionKey

  selectInput.addEventListener('change', () => {
    const newValue = selectInput.options[selectInput.selectedIndex].value
    if (newValue === '') {
      window.localStorage.removeItem(key)
    } else {
      window.localStorage.setItem(key, newValue)
    }
  })

  const existingValue = window.localStorage.getItem(key)
  if (existingValue) {
    selectInput.value = existingValue
    selectInput.dispatchEvent(new CustomEvent('change'))
  }
}

document.addEventListener('turbo:load', () => {
  const rememberSelectionInputs = document.querySelectorAll('select[data-remember-selection-key]')

  if (rememberSelectionInputs.length && storageAvailable('localStorage')) {
    rememberSelectionInputs.forEach((selectInput) => rememberSelection(selectInput))
  }
})
